/// Target both the input field and the label
///
@mixin btn--raised-target-both() {
    &,
    &::after {
        @content;
    }
}

@mixin btn--raised($sz--shadow, $c--shadow, $sz--border) {
    @include btn--apply-states(()) {
        @include btn--raised-base($sz--shadow, $c--shadow, $sz--border);
    }

    &:not(.u-reset) {
        @include btn--apply-states(('disabled': true)) {
            @include btn--raised-active($sz--border);
        }

        @include btn--apply-states(('toggled': true)) {
            @include btn--raised-active($sz--border);
        }

        @include btn--apply-states(('active': true)) {
            @include btn--raised-active($sz--border);
        }

        @include btn--apply-states(('active-route': true)) {
            @include btn--raised-active($sz--border);
        }
    }
}

/// Shadow base styles
///
@mixin btn--raised-base($sz--shadow, $c--shadow, $sz--border) {
    z-index: 1;
    position: relative;
    //margin: $sz--shadow 0 0 0;
    transform: translate(0, -$sz--shadow);
    backface-visibility: hidden;

    &, &::before, &::after {
        transition: transition-props($trs--base, color, border-color, background-color, opacity, transform);
    }

    &::after,
    &::before {
        @include btn--base();
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        box-sizing: content-box;
        padding: $sz--border;
        border: $sz--border;
    }

    &::after {
        transform: translate(-$sz--border, -$sz--border);
    }

    &::before {
        background-color: $c--shadow;
        transform: translate(-$sz--border, -$sz--border + $sz--shadow);
    }
}

/// Shadow active styles
///
@mixin btn--raised-active($sz--border) {
    transform: translate(0);

    &::before, &::after {
        transform: translate(-$sz--border, -$sz--border);
    }

    &::before {
        opacity: 0;
    }
}
