// stylelint-disable plugin/selector-bem-pattern

.md-checkbox {
    // Vars

    // Support

    // Module

    // Facets
    & {
        &.md-checkbox__in-table {
            margin: 0;
        }

        &.md-checkbox__in-table-filter-bar {
            margin: 0;

            .md-checkbox-container {
                order: 2;
            }

            .md-checkbox-label {
                order: 1;
                padding-left: 0;
                padding-right: 8px;
            }
        }
    }

    // States
}
