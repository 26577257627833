@use 'sass:math';

.tooltip {
    // Vars
    $sz--arrow: 7px;
    $sz--tooltip-offset: 8px;
    $sz--arrow-diag: math.ceil(math.div(math.div($sz--arrow, 1px) * 2, math.sqrt(2))) * 1px;
    $sz--border: 0;

    $c--tooltip-bg: $c--monochrome-dark-grey;
    $c--tooltip-text: $c--monochrome-white;
    $c--popover-bg: $c--monochrome-white;
    $c--popover-text: $c--monochrome-black;

    // Support
    @function tooltip--rotate-coords($x, $y, $θ) {
        $x2: ($x * math.cos($θ) - $y * math.sin($θ));
        $y2: ($x * math.sin($θ) + $y * math.cos($θ));
        @return ($x2, $y2);
    }

    @function tooltip--compile-transforms($modifiers-x, $modifiers-y) {
        $result: ();

        @each $value in $modifiers-x {
            $result: append($result, translateX($value), space);
        }

        @each $value in $modifiers-y {
            $result: append($result, translateY($value), space);
        }

        @if (length($result) == 0) {
            $result: none;
        }

        @return $result;
    }

    @mixin tooltip--modify($transform-modifiers, $offset-modifiers) {
        $origin-x-values: (left, center, right);
        $origin-y-values: (top, center, bottom);
        $origin-x-transform-modifiers: map-get($transform-modifiers, 'x');
        $origin-y-transform-modifiers: map-get($transform-modifiers, 'y');
        $origin-x-offset-modifiers: map-get($offset-modifiers, 'x');
        $origin-y-offset-modifiers: map-get($offset-modifiers, 'y');

        @each $origin-x-value in $origin-x-values {
            $offset-modifier-x: map-get($origin-x-offset-modifiers, $origin-x-value);
            $transform-modifier-x: map-get($origin-x-transform-modifiers, $origin-x-value);

            @each $origin-y-value in $origin-y-values {
                $offset-modifier-y: map-get($origin-y-offset-modifiers, $origin-y-value);
                $transform-modifier-y: map-get($origin-y-transform-modifiers, $origin-y-value);

                &[x-origin^='#{$origin-x-value}'][y-origin^='#{$origin-y-value}'] {
                    & {
                        transform: tooltip--compile-transforms($offset-modifier-x, $offset-modifier-y);
                    }

                    .tooltip--wrapper {
                        transform: tooltip--compile-transforms($transform-modifier-x, $transform-modifier-y);
                    }
                }
            }
        }
    }

    // Module
    & {
        z-index: 10000;
        display: block;
        max-width: 240px;
        color: $c--tooltip-bg;

        .tooltip--inner {
            z-index: 3;
        }

        .tooltip--wrapper {
            position: relative;

            &::before,
            &::after {
                @include overlay();
                content: '';
                border-radius: $sz--border-radius-base;
            }

            &::before {
                z-index: 1;
            }

            &::after {
                z-index: 2;
                background-color: $c--tooltip-bg;
            }
        }

        .tooltip--arrow {
            pointer-events: none;
            z-index: 1;
            position: absolute;
            width: $sz--arrow-diag;
            height: $sz--arrow-diag;

            &::before, &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: $sz--arrow;
                height: $sz--arrow;
                background-color: currentColor;
            }

            &::before {
                transform: translate(-50%, -50%) rotateZ(45deg);
            }

            &::after {
                transform: translate(-50%, -50%) rotateZ(45deg) translate(-$sz--border, -$sz--border);
            }
        }
    }

    // Facets
    & {
        // Base style
        &.tooltip__base,
        &.tooltip__note,
        &.tooltip__table-row {
            .tooltip--inner {
                @include type--copy-xx-small;
                position: relative;
                padding: 4px 8px 5px;
                color: $c--tooltip-text;
            }
        }

        // Arrow
        &.tooltip__show-arrow {
            &[x-origin^='left'][y-origin^='center'] {
                transform: translateX(-$sz--tooltip-offset);

                .tooltip--arrow {
                    top: 50%;
                    left: 100%;
                    transform: translate(-50%, -50%);
                }
            }

            &[x-origin^='right'][y-origin^='center'] {
                transform: translateX($sz--tooltip-offset);

                .tooltip--arrow {
                    top: 50%;
                    left: 0;
                    transform: translate(-50%, -50%);
                }
            }

            &[x-origin^='center'][y-origin^='top'] {
                transform: translateY(-$sz--tooltip-offset);

                .tooltip--arrow {
                    top: 100%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &[x-origin^='center'][y-origin^='bottom'] {
                transform: translateY($sz--tooltip-offset);

                .tooltip--arrow {
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &.tooltip__nowrap {
            width: auto;
            max-width: none;
        }

        &.tooltip__warning {
            .tooltip--inner {
                min-width: 94px;
                background: $c--signal-error;
            }
        }

        // Top menu
        &.tooltip__top-menu {
            width: 240px;
            max-width: none;
        }
    }

    // States
    & {
        // Base placement
        &.tooltip__base {
            $transform-modifiers: (
                x: (left: -100%, center: -50%, right: 0),
                y: (top: -100%, center: -50%, bottom: 0),
            );
            $offset-modifiers: (
                x: (left: -$sz--tooltip-offset, center: 0, right: $sz--tooltip-offset),
                y: (top: -$sz--tooltip-offset, center: 0, bottom: $sz--tooltip-offset),
            );

            @include tooltip--modify($transform-modifiers, $offset-modifiers);
        }

        // Table row
        &.tooltip__table-row {
            $transform-modifiers: (
                x: (left: -100%, center: -50%, right: 0),
                y: (top: 0, center: 0, bottom: 0),
            );
            $offset-modifiers: (
                x: (left: 0, center: 0, right: 0),
                y: (top: 0, center: 0, bottom: 0),
            );

            @include tooltip--modify($transform-modifiers, $offset-modifiers);
        }

        // Note
        &.tooltip__note {
            $transform-modifiers: (
                x: (left: -100%, center: -50%, right: 0),
                y: (top: 0, center: 0, bottom: 0),
            );
            $offset-modifiers: (
                x: (left: 0, center: 0, right: 0),
                y: (top: 0, center: 0, bottom: 0),
            );

            @include tooltip--modify($transform-modifiers, $offset-modifiers);
        }
    }
}
