// stylelint-disable plugin/selector-bem-pattern

.md-sidenav {
    // Vars

    // Support

    // Module
    & {
        .md-sidenav-content {
            overflow-x: auto;
            overflow-y: scroll;
        }
    }

    // Facets
    & {
        // In: order-detail
        &.md-sidenav__page-order-detail {
            .md-backdrop,
            .md-sidenav-content {
                top: $sz--top-menu-height;
            }
        }

        // Base page
        &.md-sidenav__page-table-view {
            .md-backdrop,
            .md-sidenav-content {
                top: $sz--top-menu-height + $sz--tabs-main-height;
            }
        }

        &.md-sidenav__direct-messaging {
            .md-sidenav-content {
                z-index: 102;
                width: $sz--sidenav;
            }
        }

        &.md-sidenav__chat-flyout {
            .md-sidenav-content {
                z-index: 101;
                width: $sz--sidenav + $sz--chat-flyout;
                padding-right: $sz--sidenav;
            }

            .md-backdrop {
                display: none;
            }
        }
    }

    // States
}
