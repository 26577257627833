/// Secondary
///
@mixin btn--secondary() {
    // Vars

    // Support

    // Module
    & {
        @include ff--base-medium;
        font-size: $fz--small;
        line-height: $lh--small;
        border-width: 0;
    }

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--secondary-base();
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--secondary-focus();
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--secondary-active();
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--secondary-disabled();
        }

        // Toggled
        @include btn--apply-states(('toggled': true, 'focus': default)) {
            @include btn--secondary-toggled();
        }
    }
}

/// Base
///
@mixin btn--secondary-base() {
    color: $c--monochrome-silver-grey;
    background-color: transparent;
    border-color: transparent;
}

/// Focus
///
@mixin btn--secondary-focus() {
    color: $c--monochrome-charcoal;
    background-color: rgba($c--monochrome-brown-grey, 0.2);
    border-color: transparent;
}

/// Active
///
@mixin btn--secondary-active() {
    //
}

/// Disabled
///
@mixin btn--secondary-disabled() {
    color: $c--monochrome-light-grey;
}

/// Toggled
///
@mixin btn--secondary-toggled() {
    color: $c--monochrome-white;
    background-color: $c--brand-yellow;
}
