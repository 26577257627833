.table-add-form {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;

        .table-add-form--btn-container {
            margin: 40px $sz--table-content-offset 0 0;
        }

        .table-add-form--btn {
            margin: 0;
        }

        .table-add-form--form {
            width: 100%;
        }
    }

    // Facets

    // States
}
