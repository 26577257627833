.table-col-status {
    // Vars

    // Support
    @mixin table-col-status--color($color) {
        color: $color;

        a {
            transition: color $trs--base;

            @include has-focus(false) {
                color: $color;
                text-decoration: underline;
            }

            @include has-focus(true) {
                color: $c--label-link;
                text-decoration: underline;
            }
        }
    }

    // Module

    // Facets
    & {
        &.table-col-status__info {
            @include table-col-status--color($c--signal-info);
        }

        &.table-col-status__error {
            @include table-col-status--color($c--signal-warning);
        }

        &.table-col-status__success {
            @include table-col-status--color($c--signal-success);
        }
    }

    // States
}
