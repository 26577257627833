/// Medium
///
@mixin btn--size-medium() {
    min-width: 80px;
    padding: 11px 16px 9px;
    font-size: $fz--small;
}

/// Small
///
@mixin btn--size-small() {
    padding: 11px 16px 9px;
    font-size: $fz--small;
}

/// Medium bubble
///
@mixin btn--size-bubble-medium() {
    padding: 8px;
}

/// Small bubble
///
@mixin btn--size-bubble-small() {
    padding: 4px;
}

/// Medium square
///
@mixin btn--size-square-medium() {
    padding: 5px;
}

/// Small square
///
@mixin btn--size-square-small() {
    padding: 2px;
}
