/// Font weights used throughout the project
///
$fw--light: 300;
$fw--normal: 400;
$fw--medium: 500;
$fw--bold: 700;

/// The project's font families
///
$ff--base: Roboto; // stylelint-disable-line value-keyword-case

/// Font stack definitions
/// @link http://www.cssfontstack.com/
///
// stylelint-disable value-keyword-case
$ff--base-stack: $ff--base, 'Noto Sans', Noto, sans-serif;
// stylelint-enable value-keyword-case
