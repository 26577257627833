.ui-overlay {
    // Vars

    // Support

    // Module
    & {
        .ui-overlay--root {
            z-index: z-index('overlay');
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .ui-overlay--backdrop {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba($c--monochrome-black, 0.2);
        }

        .ui-overlay--display {
            @include ios-native-scrolling;
            position: relative;
            width: 100%;
            height: 100%;
        }

        .ui-overlay--backdrop-click-area {
            @include overlay();
            z-index: 1;
        }

        .ui-overlay--wrap-outer {
            @include is-selectable(true);
            display: table;
            width: 100%;
            height: 100%;
            table-layout: fixed;
        }

        .ui-overlay--wrap-inner {
            position: relative;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }

        .ui-overlay--container {
            z-index: 2;
            position: relative;
            width: 100%;
        }

        .ui-overlay--revealer {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            text-align: left;
        }
    }

    // Facets
    & {
        // modal
        &.ui-overlay__modal {
            .ui-overlay--revealer {
                pointer-events: auto;
                width: 100%;
                max-width: 768px;
                margin: auto;
                padding: 24px;
                background: $c--monochrome-white;
                border-radius: 4px;
            }

            .ui-overlay--backdrop-click-area {
                display: none;
            }

            .ui-overlay--container {
                pointer-events: none;
            }
        }

        // Toast
        &.ui-overlay__tooltip {
            .ui-overlay--root {
                pointer-events: none;
                position: absolute;
            }

            .ui-overlay--display {
                overflow: visible;
            }

            .ui-overlay--wrap-inner {
                vertical-align: top;
            }

            .ui-overlay--backdrop {
                display: none;
            }

            .ui-overlay--backdrop-click-area {
                display: none;
            }
        }
    }

    // States
}
