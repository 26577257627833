.table-col-actions-open-chat {
    // Vars

    // Support

    // Module
    & {
        .table-col-actions-open-chat--btn {
            @include icon-utils--strike-through-base(2px, $c--monochrome-light-grey, $c--monochrome-white);
            position: relative;

            &:disabled {
                @include icon-utils--strike-through-active;
            }
        }
    }

    // Facets

    // States
}
