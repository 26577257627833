.sidenav {
    // Vars

    // Support

    // Module
    & {
        @include overlay(fixed);
        pointer-events: none;

        .sidenav--flyout {
            pointer-events: auto;
        }
    }

    // Facets

    // States
}
