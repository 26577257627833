.table-select-form {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'items', cols: ('item')));
        @include grid--space((row: 'items', cols: ('item'), gutter: 16px));
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;

        .table-select-form--btn-container {
            margin: 40px $sz--table-content-offset 0 0;
        }

        .table-select-form--btn {
            display: block;
        }

        .table-select-form--form {
            width: 100%;
        }
    }

    // Facets

    // States
}
