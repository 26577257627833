.route-tabs {
    // Vars

    // Support

    // Module
    & {
        .route-tabs--container {
            // NOTE: IE11 crashes when the `md-tabs` component has a parent with `display: flex`. Yes, it crashes...
            display: block !important; // stylelint-disable-line declaration-no-important
        }

        .route-tabs--header {
            display: flex;
            align-items: center;
        }

        .route-tabs--header-text {
            font-size: $fz--medium;
            line-height: $lh--medium;
        }

        .route-tabs--messages-badge {
            margin-left: 12px;
        }
    }

    // Facets

    // States
}
