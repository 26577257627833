&.grid {
    // Vars

    // Support

    // Module
    & {
        @include grid--root();
        @include grid--space((gutter: $sz--grid-gutter, edge: $sz--grid-edges));

        .grid--container.grid--container__fluid {
            max-width: none;
        }
    }

    // Facets

    // States
}
