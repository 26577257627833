@use 'sass:math';

.location {
    // Vars

    // Support

    // Module
    & {
        &:not(:first-child) {
            margin-top: 80px;
        }

        .location--title {
            @include type--h1;
            margin: 0 0 20px 40px;
        }

        .location--content {
            padding: 20px 40px 0;
        }

        .location--timeline {
            @include grid--span((ratio: math.div(1, 4)));
            overflow: hidden;
        }

        .location--map {
            @include grid--span((ratio: math.div(3, 4)));
            overflow: hidden;
        }

        .location--map-center-button {
            position: absolute;
            top: 100px;
            left: 20px;
        }

        // stylelint-disable-next-line plugin/selector-bem-pattern
        .location--map-center-button .leaflet-bar {
            background: rgb(248, 248, 248);
            cursor: pointer;
            padding: 3px;
        }

        // stylelint-disable-next-line plugin/selector-bem-pattern
        .location--map-center-button .leaflet-bar:hover {
            background: rgb(238, 238, 238);
            padding: 3px;
        }

        .location--map-center-button-icon {
            color: #555555;
        }

        .location--map-legend img {
            margin-top: 8px;
        }

        .location--map-legend span {
            position: relative;
            top: -8px;
            font-size: small;
            margin-left: 5px;
            margin-right: 15px;
        }

        .location--map-spanner {
            position: relative;
            width: 100%;
            padding-top: math.div(570, 800) * 100%;
        }

        .location--map-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .location--map-element {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .location--timeline-day-title {
            margin-bottom: 18px;
        }

        .location--timeline-day-entries {
            margin-bottom: 36px;
        }

        .location--timeline-entry {
            font-size: 14px;
            color: #000000;
            line-height: 1.43;
            margin-bottom: 20px;
            opacity: 0.5;
        }

        .location--timeline-entry-active {
            opacity: 1;
        }

        .location--timeline-entry-type {
            margin-left: 11px;
        }
    }

    // Facets

    // States
}
