// stylelint-disable property-no-vendor-prefix, no-duplicate-selectors, selector-max-universal, selector-no-qualifying-type

////
/// Project specific general styles
////

body {
    overflow-x: auto;
}

td {
    vertical-align: middle;
}
