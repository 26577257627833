.toggle-button-list {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'items', cols: ('item')));
        @include grid--space((row: 'items', cols: ('item'), gutter: 10px));

        padding: 20px 24px 24px;
        overflow: visible;

        .toggle-button-list--item {
            display: block;
        }

        .toggle-button-list--btn {
            position: relative;
            text-transform: uppercase;
        }
    }

    // Facets

    // States
}
