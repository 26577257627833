.table-col-actions-sidenav-open-chat {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        width: 100%;
        white-space: nowrap;

        .table-col-actions-sidenav-open-chat--link {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    // Facets

    // States
}
