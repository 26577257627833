.page-authentication {
    // Vars
    $sz--auth-content-width: 780px;

    // Support

    // Module
    & {
        .page-authentication--content {
            margin: 80px auto 0;
            max-width: $sz--auth-content-width;
            background-color: $c--monochrome-white;
            box-shadow: 0 8px 32px 0 rgba($c--monochrome-black, 0.06);
        }
    }

    // Facets

    // States
}
