/// Menu
///
@mixin btn--menu() {
    // Vars

    // Support

    // Module
    & {
        width: $sz--top-menu-height;
        height: $sz--top-menu-height;
        border-width: 0;
        padding: 0;
    }

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--menu-base();
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--menu-focus();
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--menu-active();
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--menu-disabled();
        }
    }
}

/// Base
///
@mixin btn--menu-base() {
    color: $c--brand-gradiation;
    background-color: transparent;
    border-color: transparent;
}

/// Focus
///
@mixin btn--menu-focus() {
    color: $c--monochrome-charcoal;
}

/// Active
///
@mixin btn--menu-active() {
    //
}

/// Disabled
///
@mixin btn--menu-disabled() {
    color: mix($c--brand-yellow, $c--monochrome-charcoal, 90%);
}
