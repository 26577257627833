/// Web-static folder
///
$web-static: '../..';

/// BEM options
///
$bem-element-separator: '--';
$bem-modifier-separator: '__';

/// Colors
/// see `util/colors.scss`

/// Layers (z-index)
/// see `util/z-index.scss`

/// Fonts
/// see `base/type.scss`

/// Sizes & spacing
///
$sz--grid-gutter: 16px;
$sz--grid-edges: 32px;

/// Transitions & animations
///
$trs--base-speed: 200ms;
$trs--base-fn: ease('in-out-material');
$trs--base: $trs--base-speed $trs--base-fn;

$trs--overlay-speed: 200ms;
$trs--overlay-fn: ease('in-out-material');
$trs--overlay: $trs--overlay-speed $trs--overlay-fn;

$trs--common-property-list: color, border-color, background-color, opacity, transform;
$trs--common-props: transition-props($trs--base, $trs--common-property-list...);
