// stylelint-disable plugin/selector-bem-pattern

.leaflet-container {
    a {
        text-decoration: none !important; // stylelint-disable declaration-no-important
    }

    .leaflet-popup-content-wrapper {
        border-radius: 0;
    }

    .leaflet-popup-content {
        @include ff--base-regular;
        margin: 20px;

        b {
            @include ff--base-bold;
            display: inline-block;

            &:not(:first-child) {
                margin-top: 8px;
            }
        }

        p {
            display: inline-block;

            &:not(:first-child) {
                margin-top: 4px;
            }
        }
    }
}
