.table-col-select {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        width: 120px;
        white-space: normal;

        .table-col-select--wrap {
            position: relative;
        }

        .table-col-select--data-display {
            display: inline-block;
            transform: translateY(1px); // NOTE: Visual correction
        }
    }

    // Facets

    // States
}
