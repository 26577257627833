.table-filter-bar {
    // Vars

    // Support

    // Module
    & {
        @include grid--root();
        @include grid--space((gutter: (0 16px)));
        @include grid--root((row: 'selects', cols: ('select')));
        @include grid--space((gutter: (0 16px), row: 'selects', cols: ('select')));
        padding: 60px 0 0;

        .table-filter-bar--row {
            justify-content: space-between;
        }

        .table-filter-bar--col {
            &.table-filter-bar--col__checkboxes {
                align-self: flex-end;
            }
        }
    }

    // Facets

    // States
}
