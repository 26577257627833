.messages-badge {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 24px;
        height: 24px;

        color: $c--monochrome-white;
        background-color: $c--signal-error;
        text-align: center;

        border-radius: 12px;

        .messages-badge--text {
            @include ff--base-bold;
            padding: 2px 6px;

            font-size: 1.25rem;
            line-height: 1.25;
            transform: translateY(-0.05rem);
        }
    }

    // Facets
    & {
        &.messages-badge__chat-btn {
            position: absolute;
            bottom: -6px;
            right: -6px;
        }

        &.messages-badge__top-menu {
            pointer-events: none;
            z-index: 1;
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }

    // States
}
