.top-menu {
    // Vars
    $sz--top-menu-offset: 24px;
    $sz--back-border-width: 1px;
    $c--top-menu-border: rgba($c--brand-gradiation, 0.5);

    // Support

    // Module
    & {
        background-color: $c--brand-yellow;
        border-bottom: 1px solid $c--top-menu-border;

        .top-menu--element {
            height: $sz--top-menu-height;
            min-height: $sz--top-menu-height;
            padding: 0;
        }

        .top-menu--container {
            height: 100%;
        }

        .top-menu--items,
        .top-menu--item {
            height: 100%;
        }

        .top-menu--items {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
        }

        .top-menu--item {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            &:not(:first-child) {
                border-left: $sz--back-border-width solid $c--top-menu-border;
            }

            &.top-menu--item__logo {
                flex: 1 0 auto;
                padding-left: $sz--top-menu-offset;

                &:first-child {
                    padding-left: $sz--top-menu-offset + $sz--top-menu-height + $sz--back-border-width;
                }
            }

            &.top-menu--item__btn {
                position: relative;
                justify-content: center;
                flex: 0 1 $sz--top-menu-height;

                @include has-focus(default) {
                    text-decoration: none;
                }
            }

            &.top-menu--item__search {
                padding: 0 27px;
            }
        }

        .top-menu--home-logo {
            display: block;
        }
    }

    // Facets
    & {
        & {
            .top-menu--btn {
                // to make sure the top-menu badges are behind the sidenav
                z-index: 0;
            }

            .top-menu--btn.top-menu--btn__chat {
                $c--foreground: mix($c--brand-yellow, $c--monochrome-charcoal, 90%);

                @include icon-utils--strike-through-base(2px, $c--foreground, $c--brand-yellow);
                position: relative;

                &:disabled {
                    @include icon-utils--strike-through-active;
                }
            }

            .top-menu--btn__notifications {
                display: flex;
            }
        }
    }

    // States
}
