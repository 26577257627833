@use 'sass:math';

.status-icon {
    // Vars
    $sz--aborted-line-border-width: 2px;
    $sz--aborted-line-width: math.ceil(math.div(100% * 2, math.sqrt(2)));
    $sz--aborted-line-height: 2px + (2 * $sz--aborted-line-border-width);

    // Support

    // Module
    & {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;

        .status-icon--btn {
            flex: 0 1 auto;
        }

        .status-icon--outer-wrap {
            display: block;
            width: 100%;
        }

        .status-icon--wrap {
            cursor: default;
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
        }

        // stylelint-disable-next-line selector-no-qualifying-type
        a.status-icon--wrap {
            cursor: pointer;
            transition: $trs--common-props;
        }

        .status-icon--symbol {
            pointer-events: none;
            display: block;
            min-width: auto;
            min-height: auto;
            width: 100%;
            height: 100%;
            font-size: inherit;
            cursor: default;
        }

        .status-icon--aborted-line {
            position: absolute;
            top: 50%;
            left: 50%;
            width: $sz--aborted-line-width;
            height: $sz--aborted-line-height;
            transform: translate(-50%, -50%) rotateZ(-45deg);
            background-color: $c--signal-error;
            border: $sz--aborted-line-border-width solid $c--monochrome-white;
            background-clip: content-box;
        }
    }

    // Facets
    & {
        &.status-icon__info {
            color: $c--signal-info;
        }

        &.status-icon__error {
            color: $c--signal-warning;
        }

        &.status-icon__success {
            color: $c--signal-success;
        }

        &.status-icon__passive {
            color: $c--monochrome-silver;
        }
    }

    // States
}
