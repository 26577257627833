@use 'sass:math';

.pickup-and-drop-terminal {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
            display: flex;
            flex-wrap: wrap;

            // general grid
            @include grid--root((row: 'row', cols: ('left', 'right', 'buttons', 'full')));
            @include grid--space((row: 'row', cols: ('left', 'right', 'buttons', 'full'), gutter: 24px));

            // input fields grid
            @include grid--root((row: 'input-row', cols: ('input-cell')));
            @include grid--space((row: 'input-row', cols: ('input-cell'), gutter: 16px));
        }

        .pickup-and-drop-terminal--drag-button {
            z-index: 1;
            position: absolute;
            top: -24px;
            right: -24px;
            min-height: inherit;
            width: 48px;
            height: 48px;
            padding: 0;
            cursor: grab !important; // stylelint-disable-line
        }

        .pickup-and-drop-terminal--full {
            @include grid--span((ratio: math.div(12, 12)));
        }

        .pickup-and-drop-terminal--right {
            display: flex;
            align-items: flex-end;

            @include grid--span((ratio: math.div(5, 12)));
        }

        .pickup-and-drop-terminal--left {
            @include grid--span((ratio: math.div(7, 12)));
        }

        .pickup-and-drop-terminal--row {
            @include grid--span((ratio: math.div(12, 12)));
        }

        .pickup-and-drop-terminal--buttons {
            display: flex;
            justify-content: flex-end;
            flex: 1 1 100%;
            margin-top: 24px;
        }

        .pickup-and-drop-terminal--description,
        .pickup-and-drop-terminal--title {
            margin-bottom: 16px;
        }

        .pickup-and-drop-terminal--input-table {
            margin-bottom: 24px;
        }

        .pickup-and-drop-terminal--input-cell {
            overflow: hidden;

            @include grid--span((ratio: math.div(6, 12)));

            &.pickup-and-drop-terminal--input-cell__full-width {
                @include grid--span((ratio: math.div(12, 12)));
            }
        }

        .pickup-and-drop-terminal--definition-list {
            flex: 1 1 100%;
            padding: 24px 16px;
            background: $c--monochrome-white-grey;
            font-size: $fz--small;
            line-height: 1.33;
        }

        .pickup-and-drop-terminal--definition-list-title {
            margin-bottom: 12px;

            @include ff--base-bold;
        }

        .pickup-and-drop-terminal--definition-list-description {
            color: $c--monochrome-dark-grey;
            word-break: break-word;

            &:not(:last-of-type) {
                margin-bottom: 24px;
            }
        }

        .pickup-and-drop-terminal--loader {
            color: $c--brand-yellow;
        }

        .pickup-and-drop-terminal--error {
            color: $c--signal-error;
        }

        .pickup-and-drop-terminal--save-button-wrapper {
            overflow: visible;
        }

        .pickup-and-drop-terminal--save-button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            overflow: visible;
        }

        .pickup-and-drop-terminal--save-button-loader {
            margin: 0;
        }
    }

    // Facets
    & {
        .pickup-and-drop-terminal--drag-button {
            &.pickup-and-drop-terminal--drag-button__is-dragging {
                cursor: grabbing !important; // stylelint-disable-line
            }
        }
    }

    // States
}
