// stylelint-disable selector-no-qualifying-type, plugin/selector-bem-pattern

html {
    background-color: $c--monochrome-background;
}

body {
    // Vars

    // Support

    // Module

    // Facets
    &.md-theme-default {
        background-color: $c--monochrome-background;
    }

    // States
}
