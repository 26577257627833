@use 'sass:math';
@use 'sass:color';

// stylelint-disable plugin/selector-bem-pattern

.md-table {
    // Vars
    $c--read-by-driver: #fff6ec;
    $c--alliance-partner: #ffead3;

    // Support

    // Module
    & {
        display: block;

        .md-table-body {
            border-bottom: 1px solid $c--monochrome-light-grey;
        }

        .md-table-body .md-table-row {
            border-top: 1px solid $c--monochrome-light-grey;
        }

        .md-sortable,
        .md-sortable:first-of-type {
            .md-sortable-icon {
                order: 2;
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                bottom: auto;
                transform: none;

                &.md-icon {
                    margin: 0 0 0 8px;

                    &:only-child {
                        margin: 0 auto;
                    }
                }
            }

            &.md-sorted-descending {
                .md-sortable-icon {
                    transform: rotate(180deg);
                }
            }
        }

        .md-table-head-container {
            height: auto;
        }

        .md-table-head {
            vertical-align: middle;

            .md-table-head-text {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                height: auto;
                padding: $sz--table-spacing-regular;
            }

            .md-icon {
                color: $c--monochrome-charcoal;
            }
        }

        .md-table-cell {
            height: $sz--table-cell-height;
            transition: $trs--common-props;

            .md-table-cell-container {
                padding: $sz--table-spacing-regular;

                @include at-root(html, '.ie11') {
                    display: inherit;
                }
            }
        }
    }

    // Facets
    & {
        // spread
        & {
            .md-table-head.md-table-col__spread,
            .md-table-cell.md-table-col__spread {
                width: 100%;
            }
        }

        // border-left
        & {
            .md-table-head.md-table-col__border-left,
            .md-table-cell.md-table-col__border-left {
                border-left: 1px solid $c--monochrome-light-grey;
            }
        }

        // first
        .md-table-head:first-child .md-table-head-text,
        .md-table-cell:first-child .md-table-cell-container {
            padding-left: $sz--table-content-offset;
        }

        // last
        .md-table-head:last-child .md-table-head-text,
        .md-table-cell:last-child .md-table-cell-container {
            padding-right: $sz--table-content-offset;
        }

        // align-top
        & {
            .md-table-head.md-table-col__align-top,
            .md-table-cell.md-table-col__align-top {
                vertical-align: top;
            }
        }

        & {
            // Documents
            .md-table-body .md-table-row.md-table-row__documents {
                .md-table-cell {
                    //height: auto;
                    //padding: 24px 0;
                    //vertical-align: top;
                }

                &.md-table-row__is-expanded {
                    height: $sz--table-row-expanded-height;

                    .md-table-cell {
                        height: $sz--table-row-expanded-height;
                        padding-bottom: $sz--table-row-expanded-height - $sz--table-cell-height;
                    }
                }
            }
        }

        // backgrounds
        & {
            .md-table-body .md-table-row {
                // Fix IE11 and Firefox bug that lets table-cell borders disappear
                .md-table-cell {
                    background-clip: padding-box;
                }

                // clickable row
                &.md-table-row__is-clickable {
                    .md-table-cell {
                        cursor: pointer;
                    }
                }

                // alternating background
                &:nth-child(2n + 1) {
                    .md-table-cell {
                        background-color: color.mix($c--monochrome-black, $c--monochrome-white, 100% - (math.div(100%, 256) * 254));
                    }
                }

                // non integrated subcontractor
                &.md-table-row__is-non-integrated-subcontractor {
                    .md-table-cell {
                        background-color: $c--read-by-driver;
                    }
                }

                &.md-table-row__is-alliance-partner {
                    .md-table-cell {
                        background-color: $c--alliance-partner;
                    }
                }

                // hover
                &:hover {
                    .md-table-cell {
                        background-color: rgba($c--monochrome-charcoal, 0.08);
                    }
                }
            }
        }
    }

    // States
}
