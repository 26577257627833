// stylelint-disable plugin/selector-bem-pattern

$sz--list-padding: 20px;
$sz--list-gutter: 8px;

.skel {
    ul, ol {
        // Reset styling
        &.u-reset { // stylelint-disable-line selector-no-qualifying-type
            &, > li {
                list-style: none;
            }
        }

        // Basic styling
        &:not(.u-reset) {
            padding-left: $sz--list-padding;
            list-style: none;

            > li {
                position: relative;
                display: inline-block;
                width: 100%;

                > * { // stylelint-disable selector-max-universal
                    margin-bottom: 0;
                }

                ~ li {
                    margin-top: $sz--list-gutter;
                }

                &::before {
                    position: absolute;
                    top: 0;
                    right: 100%;
                    display: inline-block;
                    width: $sz--list-padding;
                    height: auto;
                    padding-right: 4px;
                    font-size: inherit;

                    color: currentColor;
                }
            }
        }
    }

    ul {
        &:not(.u-reset) {
            > li {
                &::before {
                    content: '\2022';
                    text-align: center;
                }
            }
        }
    }

    ol {
        &:not(.u-reset) {
            counter-reset: li;

            > li {
                &::before {
                    @include ff--base-bold;
                    content: counter(li) '.';
                    counter-increment: li;
                    text-align: center;
                }
            }
        }
    }
}
