.table {
    // Vars

    // Support

    // Module
    & {
        &:not(:first-child) {
            margin-top: 80px;
        }

        .table--title {
            @include type--h1;
            margin: 0 0 20px $sz--table-header-offset;
        }

        .table--empty-message {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 60px $sz--table-content-offset;
            height: 50px;
        }

        .table--empty-message-icon {
            margin: 0 8px 0 0;
            color: $c--brand-material-yellow;
        }

        .table--empty-message-text {
            color: $c--monochrome-silver;
        }

        .table--filters {
            margin: 0 0 24px 40px;
        }

        .table--filters-btn {
            ~ .table--filters-btn {
                margin-left: 10px;
            }
        }
    }

    // Facets

    // States
}
