.table-col-actions-select-document {
    // Vars

    // Support

    // Module
    & {
        position: static;
        width: 100%;
        white-space: nowrap;

        .table-col-actions-select-document--wrap {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
        }
    }

    // Facets

    // States
}
