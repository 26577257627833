.document-preview {
    // Vars

    // Support

    // Module
    & {
        transition: opacity $trs--base;

        .document-preview--wrapper {
            position: absolute;
            top: $sz--table-cell-height;
            right: 0;
            width: calc(100vw - #{ 2 * $sz--grid-edges });
            height: $sz--table-row-expanded-height - $sz--table-cell-height;
            padding: 0 $sz--table-content-offset 32px;
        }

        .document-preview--inner {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .document-preview--loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $c--brand-yellow;
        }

        .document-preview--iframe {
            @include overlay();
        }
    }

    // Facets
    & {
        & {
            opacity: 0;
        }

        &.document-preview__is-visible {
            opacity: 1;
        }
    }

    // States
}
