.table-card {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: $c--monochrome-white;

        .table-card--loader {
            margin: 60px auto;
            color: $c--brand-material-yellow;
        }

        .table-card--error {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 60px auto;
            color: $c--brand-red;
            text-align: center;
        }
    }

    // Facets
    & {
        &.table-card__tabs {
            //
        }

        &.table-card__sidenav {
            min-height: 100%;
        }

        &.table-card__chat-flyout {
            z-index: 1;
            width: $sz--chat-flyout;
            height: 100%;
            min-height: 100%;
            box-shadow:
                0 1px 5px rgba($c--monochrome-black, 0.2),
                0 2px 2px rgba($c--monochrome-black, 0.14),
                0 3px 1px -2px rgba($c--monochrome-black, 0.12);
            overflow: hidden;
        }
    }

    // States
}
