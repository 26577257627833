////
/// Grid library for all your basic grid needs!
////

/// Dependencies and components
///
@import 'color__vars';
@import 'color__helpers';

/// Looks through all color definitions and returns the stored value
/// @param {string} $subset - The subset in which the color is stored
/// @param {number|string} $idx - The key as a name or index value
///
@function color($subset, $idx) {
    $map: map-get($color-maps, $subset);
    $color: transparent;

    @if type-of($idx) == 'number' {
        $color: nth($map, $idx);
    } @else {
        $color: map-get($map, $idx);
    }

    @if type-of($color) != 'color' {
        @error 'Color `#{$idx}` in map `#{$subset}` not found!';
    }

    @return $color;
}

/// Color var definitions (for easier autocompletion)
///
$c--monochrome-white: color(monochrome, 'white');
$c--monochrome-white-grey: color(monochrome, 'white-grey');
$c--monochrome-background: color(monochrome, 'background');
$c--monochrome-silver: color(monochrome, 'silver');
$c--monochrome-brown-grey: color(monochrome, 'brown-grey');
$c--monochrome-silver-grey: color(monochrome, 'silver-grey');
$c--monochrome-light-grey: color(monochrome, 'light-grey');
$c--monochrome-dark-grey: color(monochrome, 'dark-grey');
$c--monochrome-charcoal: color(monochrome, 'charcoal');
$c--monochrome-black: color(monochrome, 'black');

$c--brand-yellow-light: color(brand, 'yellow-light');
$c--brand-yellow: color(brand, 'yellow');
$c--brand-material-yellow: color(brand, 'material-yellow');
$c--brand-gradiation: color(brand, 'gradiation');
$c--brand-red: color(brand, 'red');

$c--label-text: color(label, 'text');
$c--label-link: color(label, 'link');
$c--label-selection: color(label, 'selection');

$c--signal-info: color(signal, 'info');
$c--signal-error: color(signal, 'error');
$c--signal-warning: color(signal, 'warning');
$c--signal-success: color(signal, 'success');

/// Finally, check if the list of color vars is complete
///
@include color--check-var-availability();
