.footer {
    // Vars

    // Support

    // Module
    & {
        border-top: 1px solid $c--monochrome-light-grey;

        .footer--items {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            height: $sz--footer-height;
        }

        .footer--item {
            &.footer--item__identity {
                flex: 1 0 auto;
            }

            &.footer--item__btn {
                margin: 0 8px;
            }
        }

        .footer--btn {
            position: relative;
            display: block;
        }

        .footer--btn-ink-ripple {
            //color: red;
        }

        .footer--identity {
            font-size: $fz--small;
            line-height: $lh--small;
            color: $c--monochrome-silver-grey;
        }
    }

    // Facets

    // States
}
