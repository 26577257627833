.leaflet-popup-data {
    // Vars
    $sz--margin: -15px;
    $sz--icon: 24px;

    // Support

    // Module
    & {
        position: relative;
        min-width: 120px;

        .leaflet-popup-data--icon {
            position: absolute;
            top: $sz--margin;
            right: $sz--margin;

            width: $sz--icon;
            height: $sz--icon;

            background-size: cover;
        }
    }

    // Facets
    & {
        & {
            .leaflet-popup-data--icon.leaflet-popup-data--icon__success {
                background-image: url('#{ popup-icons--svg($c--signal-success, $sz--icon, 'check_circle') }');
            }

            .leaflet-popup-data--icon.leaflet-popup-data--icon__error {
                background-image: url('#{ popup-icons--svg($c--signal-error, $sz--icon, 'error') }');
            }

            .leaflet-popup-data--icon.leaflet-popup-data--icon__info {
                background-image: url('#{ popup-icons--svg($c--signal-info, $sz--icon, 'info') }');
            }
        }
    }

    // States
}
