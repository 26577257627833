.table-col-base {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        width: 100%;
        white-space: nowrap;

        .table-col-base--primary-content {
            @include type--copy-x-small;
            display: inline-block;
        }

        .table-col-base--secondary-content {
            @include type--copy-xx-small;
            display: inline-block;
            color: $c--monochrome-dark-grey;
        }
    }

    // Facets
    & {
        // Wrap
        &.table-col-base__wrap {
            padding: 8px 0;
            white-space: normal;
            hyphens: none;
        }

        &.table-col-base__two-rows {
            .table-col-base--secondary-content {
                @include type--copy-x-small;
                display: inline-block;
                color: inherit;
            }
        }
    }

    // States
}
