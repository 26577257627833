@use 'sass:math';

.simple-text {
    // Vars
    $sz--aborted-line-border-width: 2px;
    $sz--aborted-line-width: math.ceil(math.div(100% * 2, math.sqrt(2)));
    $sz--aborted-line-height: 2px + (2 * $sz--aborted-line-border-width);

    // Support

    // Module
    & {
        &:not(:first-child) {
            margin-top: 80px;
        }

        .simple-text--title-wrap {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin: 0 0 20px $sz--table-header-offset;
        }

        .simple-text--title {
            @include type--h1;
        }


        .simple-text--content {
            margin: 0 0 0 $sz--table-content-offset;
        }

        .simple-text--icon-container {
            margin-left: 16px;
            margin-top: -6px;
        }

        .simple-text--icon {
            width: 24px;
            height: 24px;
        }

        .simple-text--aborted-line {
            position: relative;
            top: 18px;
            left: 50%;
            width: $sz--aborted-line-width;
            height: $sz--aborted-line-height;
            transform: translate(-50%, -50%) rotateZ(-45deg);
            background-color: $c--signal-error;
            border: $sz--aborted-line-border-width solid $c--monochrome-white;
            background-clip: content-box;
        }
    }

    // Facets

    // States
}
