
.password-field {
    // Vars

    // Support

    // Module
    & {
        max-width: 320px;

        .password-field--button {
            padding: 0 8px;
            cursor: pointer;
        }
    }

    // Facets

    // States
}
