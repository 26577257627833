/// Font-styles, usable for project-wide styling of text instances
///
@mixin ff--base-light() {
    font-family: $ff--base-stack;
    font-weight: $fw--light;
    font-style: normal;
}

@mixin ff--base-regular() {
    font-family: $ff--base-stack;
    font-weight: $fw--normal;
    font-style: normal;
}

@mixin ff--base-medium() {
    font-family: $ff--base-stack;
    font-weight: $fw--medium;
    font-style: normal;
}

@mixin ff--base-bold() {
    font-family: $ff--base-stack;
    font-weight: $fw--bold;
    font-style: normal;
}

@mixin ff--base-regular-italic() {
    font-family: $ff--base-stack;
    font-weight: $fw--normal;
    font-style: italic;
}
