// stylelint-disable plugin/selector-bem-pattern

.md-tabs {
    // Vars
    $num--skew-degree: 20deg;

    // Support

    // Module
    & {
        .md-tab-header {
            max-width: none;
        }

        .md-tab-indicator {
            height: 4px;
        }
    }

    // Facets
    & {
        // Main nav
        &.md-theme-default.md-tabs__main {
            .md-tab {
                padding: 0;
            }

            .md-tab-indicator {
                background-color: $c--monochrome-charcoal;
            }

            > .md-tabs-navigation {
                height: $sz--tabs-main-height;
                min-height: $sz--tabs-main-height;

                .md-tab-header {
                    color: $c--monochrome-black;
                }
            }
        }

        // Skewed version
        &.md-theme-default.md-tabs__skewed {
            margin-top: 40px;

            .md-tabs-navigation {
                z-index: 0;
                overflow: hidden;
                border-bottom: none;
            }

            .md-tab-header {
                @include ff--base-regular;
                text-transform: inherit;

                padding: 0 40px;
                transform: skewX($num--skew-degree);
                color: $c--monochrome-charcoal !important; // stylelint-disable-line declaration-no-important
                background-color: $c--monochrome-light-grey;
                transform-origin: bottom left;

                ~ .md-tab-header {
                    border-left: 1px solid $c--monochrome-silver;
                }

                &.md-active {
                    color: $c--monochrome-black !important; // stylelint-disable-line declaration-no-important
                    background-color: $c--monochrome-white;
                }
            }

            .md-tab-header-container {
                min-width: 150px;
                transform: skewX($num--skew-degree * -1);
            }

            .md-tab-indicator {
                display: none;
            }

            .md-tab {
                padding: 0;
            }
        }
    }

    // States
}
