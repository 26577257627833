@use 'sass:color';

/// Primary
///
@mixin btn--primary() {
    // Vars
    $sz--border: 2px;

    // Support

    // Module
    & {
        @include btn--raised(2px, $c--brand-gradiation, $sz--border);
        font-weight: $fw--medium;
        border-width: $sz--border;
        text-transform: uppercase;
    }

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--raised-target-both() {
                @include btn--primary-base();
            }
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--raised-target-both() {
                @include btn--primary-focus();
            }
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--raised-target-both() {
                @include btn--primary-active();
            }
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--raised-target-both() {
                @include btn--primary-disabled();
            }
        }

        // Toggled
        @include btn--apply-states(('toggled': true, 'focus': default)) {
            @include btn--raised-target-both() {
                @include btn--primary-toggled();
            }
        }
    }
}

/// Base
///
@mixin btn--primary-base() {
    color: $c--monochrome-charcoal;
    background-color: $c--brand-yellow;
    border-color: transparent;
}

/// Focus
///
@mixin btn--primary-focus() {
    background-color: color.mix($c--monochrome-black, $c--brand-yellow, 5%);
    border-color: transparent;
}

/// Active
///
@mixin btn--primary-active() {
    //
}

/// Disabled
///
@mixin btn--primary-disabled() {
    color: color.mix($c--monochrome-white, $c--monochrome-black, (1 - 0.26) * 100%);
    background-color: color.mix($c--monochrome-white, $c--monochrome-black, (1 - 0.11) * 100%);
}

/// Toggled
///
@mixin btn--primary-toggled() {
    cursor: default;
    color: $c--monochrome-charcoal;
    background-color: transparent;
    border-color: transparent;
}

