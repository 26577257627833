.action-button-list {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'items', cols: ('item')));
        @include grid--space((row: 'items', cols: ('item'), gutter: 4px));

        .action-button-list--items {
            align-items: center;
            flex-flow: row nowrap;
        }

        .action-button-list--item {
            display: block;
        }

        .action-button-list--btn {
            position: relative;

            &.action-button-list--btn__show-on-hover {
                opacity: 0;
                color: $c--monochrome-charcoal;

                &:disabled {
                    color: $c--monochrome-light-grey;
                }

                @include at-root('.md-table-row', ':hover') {
                    opacity: 1;
                }
            }
        }
    }

    // Facets
    & {
        .action-button-list--item {
            &.action-button-list--item__warning {
                color: $c--signal-error;
            }
        }
    }

    // States
}
