// stylelint-disable plugin/selector-bem-pattern

.md-input-container {
    // Vars

    // Support

    // Module

    // Facets
    & {
        .md-count {
            display: none;
        }

        &.md-input-container__in-menu,
        &.md-input-container__in-auth,
        &.md-input-container__in-table-filter,
        &.md-input-container__in-table {
            // Input
            & {
                // Focused
                &.md-input-focused {
                    label {
                        color: currentColor;
                    }

                    input {
                        color: currentColor;
                    }

                    &::after {
                        background-color: currentColor;
                    }
                }

                // Filled
                &.md-has-value {
                    input {
                        font-size: 13px;
                        letter-spacing: 0.01em;
                    }
                }

                // Disabled
                &.md-input-disabled {
                    & {
                        cursor: not-allowed;
                    }

                    &::after {
                        background: bottom left repeat-x;
                        background-image: linear-gradient(to right, currentColor 0%, currentColor 33%, transparent 0);
                        background-size: 4px 1px;
                    }
                }
            }

            // Select
            & {
                &.md-input-placeholder:not(.md-has-value) .md-select {
                    color: $c--monochrome-silver-grey;
                }

                .md-select:not(.md-disabled)::after {
                    color: $c--monochrome-silver-grey;
                }

                &::after {
                    background-color: $c--monochrome-silver-grey;
                }
            }

            @include at-root('.md-table-row', ':hover') {
                .md-select:not(.md-disabled)::after {
                    color: $c--monochrome-charcoal;
                }

                &::after {
                    background-color: $c--monochrome-charcoal;
                }
            }
        }

        // In table
        &.md-input-container__in-table {
            flex-flow: column nowrap;
            min-height: 0;
            margin: 0;
            padding: 0;

            input, textarea {
                @include at-root('html', '.ie11') {
                    flex-basis: auto;
                }

                @include at-root('html', '.ie10') {
                    flex-basis: auto;
                }
            }

            ~ .md-error {
                display: block;
                width: 100%;
                margin-top: 4px;
                color: $c--signal-error;
            }
        }

        // in table filter
        &.md-input-container__in-table-search-field,
        &.md-input-container__in-table-filter {
            width: 140px;
            margin-bottom: 0;

            .md-input {
                padding-right: 24px;
            }

            .md-icon.md-icon {
                position: absolute;
                right: 0;
            }

            & {
                .md-icon.md-icon-base {
                    @include is-visible(true, $trs--base-speed);
                }

                .md-icon.md-icon-focused {
                    @include is-visible(false, $trs--base-speed);
                }
            }

            &.md-input-focused {
                .md-icon.md-icon {
                    color: currentColor;
                }

                .md-icon.md-icon-base {
                    @include is-visible(false, $trs--base-speed);
                }

                .md-icon.md-icon-focused {
                    @include is-visible(true, $trs--base-speed);
                }
            }
        }

        &.md-input-container__in-table-search-field {
            padding-top: 0;
            margin-top: 0;
            min-height: auto;
        }

        // In auth
        &.md-input-container__in-menu {
            // Normal
            & {
                width: 300px;

                &::after {
                    background-color: $c--brand-gradiation;
                }

                .md-icon:not(.md-icon-delete) {
                    color: $c--brand-gradiation;
                }
            }

            // Disabled
            &.md-input-disabled {
                $c--disabled: $c--brand-gradiation;

                color: $c--disabled;

                label,
                .md-icon:not(.md-icon-delete) {
                    color: rgba($c--disabled, 0.75);
                }
            }
        }

        &.md-input-container__in-auth {
            max-width: 300px;
        }

        // Direct messaging
        &.md-input-container__chat {
            margin: 0;

            .md-input {
                caret-color: $c--monochrome-charcoal;
            }

            .md-count {
                top: 100%;
                right: auto;
                left: 36px;
                bottom: auto;
                display: block;
                margin-top: 8px;
            }
        }
    }

    // States
}
