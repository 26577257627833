.table-filter-checkbox {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        align-items: center;
    }

    // Facets

    // States
}
