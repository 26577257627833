@use 'sass:color';

/// Secondary
///
@mixin btn--outline() {
    // Vars

    // Support

    // Module
    & {
        @include ff--base-medium;
        position: relative;
        font-weight: $fw--medium;
        border-width: 2px;
        text-transform: uppercase;
    }

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--outline-base();
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--outline-focus();
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--outline-active();
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--outline-disabled();
        }

        // Toggled
        @include btn--apply-states(('toggled': true, 'focus': default)) {
            @include btn--outline-toggled();
        }
    }
}

/// Base
///
@mixin btn--outline-base() {
    color: $c--monochrome-silver-grey;
    background-color: transparent;
    border-color: $c--monochrome-silver-grey;
}

/// Focus
///
@mixin btn--outline-focus() {
    color: $c--monochrome-charcoal;
    border-color: $c--monochrome-charcoal;
}

/// Active
///
@mixin btn--outline-active() {
    //
}

/// Disabled
///
@mixin btn--outline-disabled() {
    color: color.mix($c--monochrome-white, $c--monochrome-black, (1 - 0.26) * 100%);
    background-color: color.mix($c--monochrome-white, $c--monochrome-black, (1 - 0.11) * 100%);
    border-color: transparent;
}

/// Toggled
///
@mixin btn--outline-toggled() {
    color: $c--monochrome-white;
    background-color: $c--brand-yellow;
}
