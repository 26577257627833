.table-col-product {
    // Vars
    $column-min-width: 100px;
    $column-max-width: 100px;

    // Support

    // Module
    & {
        width: 100%;
        min-width: $column-min-width;
        max-width: $column-max-width;

        padding: 8px 0;
        white-space: normal;
        word-break: break-all;
        hyphens: auto;
    }

    // Facets

    // States
}
