.table-head {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .table-head--title {
            @include ff--base-medium;
            font-size: $fz--x-small;
            line-height: $lh--x-small;
            color: $c--monochrome-charcoal;
            hyphens: none;
        }

        .table-head--tooltip {
            margin-left: 4px;
            color: $c--monochrome-charcoal;
        }
    }

    // Facets

    // States
}
