.authentication {
    // Vars

    // Support

    // Module
    & {
        .authentication--block {
            padding: 25px 40px;

            &:first-child {
                padding-top: 40px;
            }

            &:last-child {
                padding-bottom: 40px;
            }

            ~ .authentication--block {
                border-top: 1px solid $c--monochrome-light-grey;
            }
        }
    }

    // Facets

    // States
}
