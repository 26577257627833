.multi-table {
    // Vars

    // Support

    // Module
    & {
        &:not(:first-child) {
            margin-top: 80px;
        }

        .multi-table--title {
            @include type--h1;
            display: inline-block;
            margin: 0 0 20px 24px;
        }

        .multi-table--table.multi-table--table {
            display: block;
            margin-top: 20px;
            overflow: visible;
        }
    }

    // Facets

    // States
}
