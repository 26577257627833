.auth-form {
    // Vars

    // Support

    // Module
    & {
        //.auth-form--intro {}

        .auth-form--title {
            margin-bottom: 4px;
        }

        .auth-form--form {
            margin-top: 40px;
        }

        .auth-form--submit {
            margin: 20px 0 0;
        }

        .auth-form--footer {
            margin-top: 20px;
        }

        .auth-form--field-errors,
        .auth-form--non-field-errors {
            color: $c--signal-error;
            margin-top: 20px;
        }
    }

    // Facets

    // States
}
