.table-col-bic-input-field {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-direction: column;

        .table-col-bic-input-field--container {
            display: flex;
            align-items: center;
        }

        .table-col-bic-input-field--inputs {
            display: flex;
            align-items: center;
        }

        .table-col-bic-input-field--input {
            border-bottom: 1px solid $c--monochrome-black;
            padding: 4px 0;
            margin: 0 2px;
            min-height: 32px;

            &:focus {
                border-bottom: 2px solid $c--monochrome-black;
            }
        }

        .table-col-bic-input-field--error {
            display: block;
            width: 100%;
            margin-top: 4px;
            color: $c--signal-error;
        }
    }

    // Facets

    & {
        .table-col-bic-input-field--input {
            &.table-col-bic-input-field--input__medium {
                max-width: 64px;
            }

            &.table-col-bic-input-field--input__small {
                max-width: 32px;
            }
        }
    }

    // States

    & {
        &.table-col-bic-input-field__has-errors {
            .table-col-bic-input-field--input {
                color: $c--signal-error;
                border-bottom-color: $c--signal-error;

                &:focus, &:hover {
                    border-bottom-color: $c--monochrome-black;
                }
            }
        }
    }
}
