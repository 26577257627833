.chat {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: column nowrap;

        .chat--scroll-observable {
            @include is-visible(false, $trs--base-speed, opacity, height);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 1px;
            opacity: 0;

            &::after {
                content: '';
            }

            &.chat--scroll-observable__is-loading {
                @include is-visible(true, $trs--base-speed, opacity, height);
                height: 60px;
                opacity: 1;

                &::after {
                    content: 'loading...';
                }
            }
        }

        .chat--title {
            @include type--h1;
            margin: 40px;
        }

        .chat--detail-link {
            cursor: pointer;
        }

        .chat--content {
            flex: 1 1 auto;
            padding: 0 40px;
            overflow-x: hidden;
            overflow-y: scroll;
        }

        .chat--header {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            margin-bottom: 40px;
        }

        .chat--day ~ .chat--day {
            margin-top: 40px;
        }

        .chat--day-label {
            @include ff--base-regular;
            font-size: $fz--small;
            line-height: $lh--small;
            padding: 5px 8px 3px;
            border-radius: 12px;
            background-color: $c--brand-yellow-light;
        }

        .chat--message {
            ~ .chat--message {
                margin-top: 32px;
            }
        }

        .chat--message-header {
            display: flex;
            flex-flow: row nowrap;
            margin-bottom: 8px;
        }

        .chat--sender-name {
            @include ff--base-bold;
        }

        .chat--sender-date {
            margin-left: 12px;
            color: $c--monochrome-silver-grey;
        }

        .chat--message-text-wrap {
            max-width: 800px;
        }

        .chat--message-text {
            text-align: left;
            text-align: start; // stylelint-disable-line declaration-block-no-duplicate-properties
        }

        .chat--input-mask {
            display: flex;
            flex-flow: column nowrap;
            flex: 0 0 auto;
        }

        .chat--send {
            align-self: flex-end;
            margin: 8px 0 0;
        }

        .chat--send-btn.chat--send-btn {
            //position: relative;
            margin: 0;

            //@include has-focus(true) {
            //    background-color: $c--brand-yellow-light;
            //}
        }
    }

    // Facets
    & {
        &.chat__base {
            max-height: 60vh;

            @include at-root('html', '.ie11') {
                height: 60vh;
            }

            .chat--input-mask {
                padding: 40px 40px 0;
            }
        }

        &.chat__sidenav {
            height: 100%;
            max-height: 100%;

            .chat--input-mask {
                padding: 40px;
            }
        }

        // Driver message
        & {
            .chat--message.chat--message__driver {
                .chat--sender-name {
                    color: $c--monochrome-charcoal;
                }
            }
        }

        // Subi message
        & {
            .chat--message.chat--message__subi {
                .chat--sender-name {
                    color: $c--monochrome-silver;
                }
            }
        }
    }

    // States
}
