////
/// Button facets and state generation library
////

/// Dependencies and components
///
@import 'btn__helpers';
@import 'btn__states';
@import 'btn__facets';
@import 'btn__raised';


/// All applicable btn facets, sizes and special types
///
.btn {
    // Vars

    // Support

    // Module
    & {
        @include btn--all();
    }

    // Facets
    & {
        // Sizes
        &.btn__small {
            @include btn--size-small();
        }

        &.btn__medium {
            @include btn--size-medium();
        }

        // Facets
        &.btn__primary {
            @include btn--primary();
        }

        &.btn__secondary {
            @include btn--secondary();
        }

        &.btn__outline {
            @include btn--outline();
        }

        &.btn__menu {
            @include btn--menu();
        }

        // Special
        &.btn__has-grid {
            @include btn--has-grid();
        }

        // Bubble
        &.btn__bubble {
            @include btn--bubble();
        }

        // Square
        &.btn__square {
            @include btn--square();
        }
    }

    // States
}
