// stylelint-disable property-no-vendor-prefix, no-duplicate-selectors, selector-max-universal, selector-no-qualifying-type, plugin/selector-bem-pattern

////
/// Support styles, that fix errors or enhance defaults - Should be valid throughout the document
////


/// Template or controller structures that must not be shown
///
.templates, .template, .controllers, .controller {
    display: none;
}


/// Elements that are resources for native browser or javascript processes
///
.u-icon-source, .u-icon-sprite, .u-svg-filter-source {
    @include hide-visually;
}


/// Set `box-sizing: border-box` for all elements as a default behaviour
///
html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
    text-decoration: none;
}


/// IE10 mobile and hybrid fix
///
* {
    -ms-touch-action: pan-y;
    -ms-content-zooming: none;
}


/// Force the outermost tags to always fill the screen
///
html, body {
    width: 100%;
    height: 100%;
}


/// General styles for the `html` element
///
html {
    // Removes the 300ms touch delay on windows phones and Chrome/Firefox
    -ms-touch-action: manipulation;
    touch-action: manipulation;

    // Disable scrolling on the `html` tag
    overflow: visible;
}


/// General styles for the `body` element
///
body {
    // Always use the `body` element as the scroll root and add native scroll for iOS devices
    @include ios-native-scrolling;

    // Make text selectable throughout the page
    @include is-selectable(true);

    // Fix many `z-index` related problems on Android 2.3 Stock Browser
    z-index: 0;

    // Reset the cursor to auto for all browsers
    cursor: auto;

    // Remove default background colors in some browsers
    background-color: transparent;

    // Type legibility improvements where applicable - Use with care
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    // Improve the browser's capability to break words naturally
    // The language is defined by the `lang` attribute on the `html` tag
    hyphens: auto;
    overflow-wrap: break-word;

    // IE10, 11 and Edge: Hide scrollbars when not in use
    -ms-overflow-style: -ms-autohiding-scrollbar;
}


/// Streamline `main` element's `display` property for IE10+
///
main {
    display: block;
}


/// Remove the default context menu on longpress, use carefully - Activating this, has an impact on accessibility!
///
// body { -webkit-touch-callout: none; }
// a { -webkit-user-select: none; }
