.table-col-icon {
    // Vars
    $sz--indicator: 16px;
    $sz--flexible-flag: 12px;

    // Support

    // Module
    & {
        position: relative;
        width: auto;
        white-space: nowrap;

        .table-col-icon--indicator {
            &.table-col-icon--indicator__top,
            &.table-col-icon--indicator__bottom {
                pointer-events: none;
                position: absolute;
                width: $sz--indicator;
                height: $sz--indicator;
                font-size: $sz--indicator;
            }

            &.table-col-icon--indicator__top {
                top: $sz--indicator * -0.5;
                right: $sz--indicator * -0.5;
            }

            &.table-col-icon--indicator__bottom {
                bottom: $sz--indicator * -0.5;
                right: $sz--indicator * -0.5;
            }
        }

        .table-col-icon--flexible-flag {
            font-size: $sz--flexible-flag;
            position: relative;
            left: -7px;
            top: -2px;
        }
    }

    // Facets
    & {
        &,
        &.table-col-icon__size-medium {
            width: 24px;
            height: 24px;
            font-size: 24px;
        }

        &.table-col-icon__size-large {
            width: 36px;
            height: 36px;
            font-size: 36px;
        }
    }

    // States
}
