.runtime-autocomplete {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }

    // Facets

    // States
}
