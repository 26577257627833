.page-notification-view {
    // Vars

    // Support

    // Module
    & {
        .page-notification-view--title {
            padding: 60px 0 0;
        }

        .page-notification-view--container {
            padding: 20px 0 0;
            // to make sure the notification items are behind the sidenav
            z-index: 0;
        }

        .page-notification-view--item .md-list-item-container {
            display: flex;
            justify-content: flex-start;
        }

        .page-notification-view--empty-message {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 20px $sz--table-content-offset;
        }

        .page-notification-view--empty-message-icon {
            margin: 0 8px 0 0;
            color: $c--brand-material-yellow;
        }

        .page-notification-view--empty-message-text {
            color: $c--monochrome-silver;
        }
    }

    // Facets

    // States
}
