// stylelint-disable plugin/selector-bem-pattern

.md-backdrop {
    // Vars

    // Support

    // Module
    & {
        // backdrop should be positioned fixed to work on scrolled pages
        // https://github.com/vuematerial/vue-material-old/blob/master/src/components/mdBackdrop/mdBackdrop.scss
        position: fixed;
    }

    // Facets

    // States
}
