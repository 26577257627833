// stylelint-disable plugin/selector-bem-pattern

/// Global type definitions
///
$fz--medium: 1.6rem;
$fz--small: 1.4rem;
$fz--x-small: 1.3rem;
$fz--xx-small: 1.0rem;

$lh--medium: 1.2;
$lh--small: 1.25;
$lh--x-small: 1.3;
$lh--xx-small: 1.25;

/// Headline definitions
///
@mixin type--h1 {
    @include ff--base-medium;
    font-size: 2.4rem;
    line-height: 1.33;
}

@mixin type--h2 {
    @include ff--base-regular;
    font-size: 2.0rem;
    line-height: 1.3;
}

@mixin type--h3 {
    @include ff--base-bold;
    font-size: 1.4rem;
    line-height: 1.43;
}

@mixin type--h4 {
    @include ff--base-regular;
    font-size: 1.6rem;
    line-height: 1.38;
}

@mixin type--h5 {
    @include ff--base-regular;
    font-size: 1.6rem;
    line-height: 1.38;
}

@mixin type--h6 {
    @include ff--base-regular;
    font-size: 1.6rem;
    line-height: 1.38;
}

@mixin type--p {
    font-size: $fz--medium;
    line-height: $lh--medium;
}

@mixin type--copy-small {
    font-size: $fz--small;
    line-height: $lh--small;
}

@mixin type--copy-x-small {
    font-size: $fz--x-small;
    line-height: $lh--x-small;
}

@mixin type--copy-xx-small {
    font-size: $fz--xx-small;
    line-height: $lh--xx-small;
}

/// Basic typographic styling
///
html {
    font-size: 62.5%;
}

body {
    @include ff--base-regular;
    @include type--p;

    color: $c--label-text;
}

/// Headlines
///
.skel {
    h1:not(.u-reset),
    .u-reset__h1 {
        @include type--h1;
    }

    h2:not(.u-reset),
    .u-reset__h2 {
        @include type--h2;
    }

    h3:not(.u-reset),
    .u-reset__h3 {
        @include type--h3;
    }

    h4:not(.u-reset),
    .u-reset__h4 {
        @include type--h4;
    }

    h5:not(.u-reset),
    .u-reset__h5 {
        @include type--h5;
    }

    h6:not(.u-reset),
    .u-reset__h6 {
        @include type--h6;
    }

    /// Tags with visual implication
    ///
    b, strong {
        font-weight: $fw--medium;
    }

    em {
        @include ff--base-regular-italic;
    }

    sub {
        font-size: $fz--x-small;
        vertical-align: sub;
    }

    sup {
        font-size: $fz--x-small;
        vertical-align: super;
    }

    /// Simple link
    ///
    a, .u-link {
        color: currentColor;
        text-decoration: none;

        &:not(.u-reset):not(.btn) {
            @include has-focus(false) {
                color: $c--label-link;
                text-decoration: underline;
            }

            @include has-focus(true) {
                text-decoration: underline;
            }
        }
    }

    /// Misc tags and definitions
    ///
    hr {
        display: block;
        border: 0;
        height: 1px;
        color: inherit; // NOTE: Firefox (v52) needs `color: inherit` to properly display `currentColor`
        background-color: currentColor;
    }

    /// Preformatted text (code)
    ///
    pre, code {
        font-family: monospace;
    }
}
