.table-col-input {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        width: 120px;
        margin-right: 30px;
        white-space: normal;

        .table-col-input--wrap {
            position: relative;
        }

        .table-col-input--data-display {
            display: inline-block;
        }

        .table-col-input--tooltip {
            position: absolute;
            right: -25px;
            top: 4px;
            display: block;
        }
    }

    // Facets

    // States
}
