.md-theme-default :not(input):not(textarea)::selection {
    background: #616161;
    color: rgba(255, 255, 255, .87)
}

.md-theme-default a:not(.md-button) {
    color: #616161
}

.md-theme-default a:not(.md-button):hover {
    color: #424242
}

body.md-theme-default {
    background-color: #ffffff;
    color: rgba(0, 0, 0, .87)
}

.md-theme-default .md-caption, .md-theme-default .md-display-1, .md-theme-default .md-display-2, .md-theme-default .md-display-3, .md-theme-default .md-display-4 {
    color: rgba(0, 0, 0, .57)
}

.md-theme-default code:not(.hljs) {
    background-color: rgba(255, 255, 255, 0.2);
    color: #424242
}

.md-theme-default.md-avatar.md-primary.md-avatar-icon {
    background-color: #ffc726
}

.md-theme-default.md-avatar.md-primary.md-avatar-icon .md-icon {
    color: rgba(255, 255, 255, .99999)
}

.md-theme-default.md-avatar.md-accent.md-avatar-icon {
    background-color: #616161
}

.md-theme-default.md-avatar.md-accent.md-avatar-icon .md-icon {
    color: rgba(255, 255, 255, .99999)
}

.md-theme-default.md-avatar.md-warn.md-avatar-icon {
    background-color: #ff5722
}

.md-theme-default.md-avatar.md-warn.md-avatar-icon .md-icon {
    color: rgba(255, 255, 255, .99999)
}

.md-theme-default.md-bottom-bar.md-fixed {
    background-color: #ffffff
}

.md-theme-default.md-bottom-bar.md-fixed .md-bottom-bar-item {
    color: rgba(0, 0, 0, .54)
}

.md-theme-default.md-bottom-bar.md-fixed .md-bottom-bar-item:hover:not([disabled]):not(.md-active) {
    color: rgba(0, 0, 0, .87)
}

.md-theme-default.md-bottom-bar.md-fixed .md-bottom-bar-item.md-active {
    color: #ffc726
}

.md-theme-default.md-bottom-bar.md-fixed.md-accent .md-bottom-bar-item.md-active {
    color: #616161
}

.md-theme-default.md-bottom-bar.md-fixed.md-warn .md-bottom-bar-item.md-active {
    color: #ff5722
}

.md-theme-default.md-bottom-bar.md-fixed.md-transparent .md-bottom-bar-item.md-active {
    color: rgba(0, 0, 0, .87)
}

.md-theme-default.md-bottom-bar.md-shift {
    background-color: #ffc726;
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-bottom-bar.md-shift .md-bottom-bar-item {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-bottom-bar.md-shift .md-bottom-bar-item:hover:not([disabled]):not(.md-active) {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-bottom-bar.md-shift .md-bottom-bar-item.md-active {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-bottom-bar.md-shift.md-accent {
    background-color: #616161
}

.md-theme-default.md-bottom-bar.md-shift.md-accent .md-bottom-bar-item {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-bottom-bar.md-shift.md-accent .md-bottom-bar-item:hover:not([disabled]):not(.md-active) {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-bottom-bar.md-shift.md-accent .md-bottom-bar-item.md-active {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-bottom-bar.md-shift.md-warn {
    background-color: #ff5722
}

.md-theme-default.md-bottom-bar.md-shift.md-warn .md-bottom-bar-item {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-bottom-bar.md-shift.md-warn .md-bottom-bar-item:hover:not([disabled]):not(.md-active) {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-bottom-bar.md-shift.md-warn .md-bottom-bar-item.md-active {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-bottom-bar.md-shift.md-transparent {
    background-color: transparent
}

.md-theme-default.md-bottom-bar.md-shift.md-transparent .md-bottom-bar-item {
    color: rgba(0, 0, 0, .54)
}

.md-theme-default.md-bottom-bar.md-shift.md-transparent .md-bottom-bar-item:hover:not([disabled]):not(.md-active) {
    color: rgba(0, 0, 0, .87)
}

.md-theme-default.md-bottom-bar.md-shift.md-transparent .md-bottom-bar-item.md-active {
    color: rgba(0, 0, 0, .87)
}

.md-theme-default.md-button:not([disabled]).md-raised:not(.md-icon-button) {
    color: rgba(0, 0, 0, .87);
    background-color: #ffffff
}

.md-theme-default.md-button:not([disabled]).md-raised:not(.md-icon-button):hover {
    background-color: #ffffff
}

.md-theme-default.md-button:not([disabled]).md-raised.md-icon-button:not(.md-raised) {
    color: rgba(0, 0, 0, .87)
}

.md-theme-default.md-button:not([disabled]).md-fab {
    color: rgba(255, 255, 255, .87);
    background-color: #616161
}

.md-theme-default.md-button:not([disabled]).md-fab:hover {
    background-color: #757575
}

.md-theme-default.md-button:not([disabled]).md-fab.md-clean {
    color: rgba(0, 0, 0, .87);
    background-color: #ffffff
}

.md-theme-default.md-button:not([disabled]).md-fab.md-clean:hover {
    background-color: #ffffff
}

.md-theme-default.md-button:not([disabled]).md-primary:not(.md-icon-button) {
    color: #ffc726
}

.md-theme-default.md-button:not([disabled]).md-primary.md-raised, .md-theme-default.md-button:not([disabled]).md-primary.md-fab {
    background-color: #ffc726;
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-button:not([disabled]).md-primary.md-raised:hover, .md-theme-default.md-button:not([disabled]).md-primary.md-fab:hover {
    background-color: #ffc122
}

.md-theme-default.md-button:not([disabled]).md-primary.md-icon-button:not(.md-raised) {
    color: #ffc726
}

.md-theme-default.md-button:not([disabled]).md-accent:not(.md-icon-button) {
    color: #616161
}

.md-theme-default.md-button:not([disabled]).md-accent.md-raised {
    background-color: #616161;
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-button:not([disabled]).md-accent.md-raised:hover {
    background-color: #757575
}

.md-theme-default.md-button:not([disabled]).md-accent.md-icon-button:not(.md-raised) {
    color: #616161
}

.md-theme-default.md-button:not([disabled]).md-warn:not(.md-icon-button) {
    color: #ff5722
}

.md-theme-default.md-button:not([disabled]).md-warn.md-raised, .md-theme-default.md-button:not([disabled]).md-warn.md-fab {
    background-color: #ff5722;
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-button:not([disabled]).md-warn.md-raised:hover, .md-theme-default.md-button:not([disabled]).md-warn.md-fab:hover {
    background-color: #f4511e
}

.md-theme-default.md-button:not([disabled]).md-warn.md-icon-button:not(.md-raised) {
    color: #ff5722
}

.md-theme-default.md-button-toggle .md-button:after {
    width: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    content: " "
}

.md-theme-default.md-button-toggle .md-toggle {
    color: rgba(0, 0, 0, .54);
    background-color: rgba(0, 0, 0, .26)
}

.md-theme-default.md-button-toggle .md-toggle:hover:not([disabled]) {
    background-color: rgba(0, 0, 0, .38)
}

.md-theme-default.md-button-toggle .md-toggle + .md-toggle:after {
    background-color: rgba(0, 0, 0, .12)
}

.md-theme-default.md-button-toggle.md-raised .md-toggle {
    color: rgba(0, 0, 0, .54);
    background-color: rgba(0, 0, 0, .26)
}

.md-theme-default.md-button-toggle.md-raised .md-toggle:hover:not([disabled]) {
    background-color: rgba(0, 0, 0, .38)
}

.md-theme-default.md-button-toggle.md-raised .md-toggle + .md-toggle:after {
    background-color: rgba(0, 0, 0, .12)
}

.md-theme-default.md-button-toggle.md-primary .md-toggle {
    color: rgba(255, 255, 255, .87);
    background-color: #ffc726
}

.md-theme-default.md-button-toggle.md-primary .md-toggle:hover:not([disabled]) {
    background-color: #ffc726
}

.md-theme-default.md-button-toggle.md-primary .md-toggle + .md-toggle:after {
    background-color: #ffc122
}

.md-theme-default.md-button-toggle.md-accent .md-toggle {
    color: rgba(255, 255, 255, .87);
    background-color: #616161
}

.md-theme-default.md-button-toggle.md-accent .md-toggle:hover:not([disabled]) {
    background-color: #616161
}

.md-theme-default.md-button-toggle.md-accent .md-toggle + .md-toggle:after {
    background-color: #757575
}

.md-theme-default.md-button-toggle.md-warn .md-toggle {
    color: rgba(255, 255, 255, .87);
    background-color: #ff5722
}

.md-theme-default.md-button-toggle.md-warn .md-toggle:hover:not([disabled]) {
    background-color: #ff5722
}

.md-theme-default.md-button-toggle.md-warn .md-toggle + .md-toggle:after {
    background-color: #f4511e
}

.md-theme-default.md-button-toggle [disabled] {
    color: rgba(0, 0, 0, 0.26)
}

.md-theme-default.md-button-toggle [disabled].md-toggle {
    color: rgba(0, 0, 0, .2);
    background-color: rgba(0, 0, 0, 0.26)
}

.md-theme-default.md-card {
    background-color: #ffffff
}

.md-theme-default.md-card.md-primary {
    background-color: #ffc726;
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-primary .md-card-header .md-icon-button:not(.md-primary):not(.md-warn):not(.md-accent) .md-icon, .md-theme-default.md-card.md-primary .md-card-actions .md-icon-button:not(.md-primary):not(.md-warn):not(.md-accent) .md-icon {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-primary .md-input-container.md-input-focused input, .md-theme-default.md-card.md-primary .md-input-container.md-input-focused textarea {
    color: rgba(255, 255, 255, .87);
    text-shadow: 0 0 0 rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-primary .md-input-container.md-input-focused label, .md-theme-default.md-card.md-primary .md-input-container.md-input-focused .md-icon:not(.md-icon-delete) {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-primary .md-input-container:after {
    background-color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-primary .md-input-container input, .md-theme-default.md-card.md-primary .md-input-container textarea {
    color: rgba(255, 255, 255, .87);
    text-shadow: 0 0 0 rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-primary .md-input-container input::-webkit-input-placeholder, .md-theme-default.md-card.md-primary .md-input-container textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-card.md-primary .md-input-container label, .md-theme-default.md-card.md-primary .md-input-container .md-icon:not(.md-icon-delete) {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-primary .md-card-expand .md-card-actions {
    background-color: #ffc726
}

.md-theme-default.md-card.md-accent {
    background-color: #616161;
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-accent .md-card-header .md-icon-button:not(.md-primary):not(.md-warn):not(.md-accent) .md-icon, .md-theme-default.md-card.md-accent .md-card-actions .md-icon-button:not(.md-primary):not(.md-warn):not(.md-accent) .md-icon {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-accent .md-input-container.md-input-focused input, .md-theme-default.md-card.md-accent .md-input-container.md-input-focused textarea {
    color: rgba(255, 255, 255, .87);
    text-shadow: 0 0 0 rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-accent .md-input-container.md-input-focused label, .md-theme-default.md-card.md-accent .md-input-container.md-input-focused .md-icon:not(.md-icon-delete) {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-accent .md-input-container:after {
    background-color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-accent .md-input-container input, .md-theme-default.md-card.md-accent .md-input-container textarea {
    color: rgba(255, 255, 255, .87);
    text-shadow: 0 0 0 rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-accent .md-input-container input::-webkit-input-placeholder, .md-theme-default.md-card.md-accent .md-input-container textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-card.md-accent .md-input-container label, .md-theme-default.md-card.md-accent .md-input-container .md-icon:not(.md-icon-delete) {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-accent .md-card-expand .md-card-actions {
    background-color: #616161
}

.md-theme-default.md-card.md-warn {
    background-color: #ff5722;
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-warn .md-card-header .md-icon-button:not(.md-primary):not(.md-warn):not(.md-accent) .md-icon, .md-theme-default.md-card.md-warn .md-card-actions .md-icon-button:not(.md-primary):not(.md-warn):not(.md-accent) .md-icon {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-warn .md-input-container.md-input-focused input, .md-theme-default.md-card.md-warn .md-input-container.md-input-focused textarea {
    color: rgba(255, 255, 255, .87);
    text-shadow: 0 0 0 rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-warn .md-input-container.md-input-focused label, .md-theme-default.md-card.md-warn .md-input-container.md-input-focused .md-icon:not(.md-icon-delete) {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-warn .md-input-container:after {
    background-color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-warn .md-input-container input, .md-theme-default.md-card.md-warn .md-input-container textarea {
    color: rgba(255, 255, 255, .87);
    text-shadow: 0 0 0 rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-warn .md-input-container input::-webkit-input-placeholder, .md-theme-default.md-card.md-warn .md-input-container textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-card.md-warn .md-input-container label, .md-theme-default.md-card.md-warn .md-input-container .md-icon:not(.md-icon-delete) {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-card.md-warn .md-card-expand .md-card-actions {
    background-color: #ff5722
}

.md-theme-default.md-card .md-card-header .md-icon-button:not(.md-primary):not(.md-warn):not(.md-accent) .md-icon, .md-theme-default.md-card .md-card-actions .md-icon-button:not(.md-primary):not(.md-warn):not(.md-accent) .md-icon {
    color: rgba(0, 0, 0, .54)
}

.md-theme-default.md-card > .md-card-area:after {
    background-color: rgba(0, 0, 0, .12)
}

.md-theme-default.md-card .md-card-media-cover.md-text-scrim .md-backdrop {
    background: linear-gradient(to bottom, rgba(0, 0, 0, .0) 20%, rgba(0, 0, 0, .275) 66%, rgba(0, 0, 0, .55) 100%)
}

.md-theme-default.md-card .md-card-media-cover.md-solid .md-card-area {
    background-color: rgba(0, 0, 0, .4)
}

.md-theme-default.md-card .md-card-media-cover .md-card-header .md-icon-button:not(.md-primary):not(.md-warn):not(.md-accent) .md-icon, .md-theme-default.md-card .md-card-media-cover .md-card-actions .md-icon-button:not(.md-primary):not(.md-warn):not(.md-accent) .md-icon {
    color: #ffffff
}

.md-theme-default.md-card .md-card-expand .md-card-actions {
    background-color: #ffffff
}

.md-theme-default.md-checkbox.md-checked .md-checkbox-container {
    background-color: #616161;
    border-color: #616161
}

.md-theme-default.md-checkbox.md-checked .md-checkbox-container:after {
    border-color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-checkbox .md-ink-ripple {
    color: #616161
}

.md-theme-default.md-checkbox .md-ripple {
    opacity: .26
}

.md-theme-default.md-checkbox.md-primary.md-checked .md-checkbox-container {
    background-color: #ffc726;
    border-color: #ffc726
}

.md-theme-default.md-checkbox.md-primary.md-checked .md-checkbox-container:after {
    border-color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-checkbox.md-primary .md-ink-ripple {
    color: #ffc726
}

.md-theme-default.md-checkbox.md-warn.md-checked .md-checkbox-container {
    background-color: #ff5722;
    border-color: #ff5722
}

.md-theme-default.md-checkbox.md-warn.md-checked .md-checkbox-container:after {
    border-color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-checkbox.md-warn .md-ink-ripple {
    color: #ff5722
}

.md-theme-default.md-checkbox.md-disabled.md-checked .md-checkbox-container {
    background-color: rgba(0, 0, 0, 0.26);
    border-color: transparent
}

.md-theme-default.md-checkbox.md-disabled:not(.md-checked) .md-checkbox-container {
    border-color: rgba(0, 0, 0, 0.26)
}

.md-theme-default.md-chip {
    background-color: rgba(0, 0, 0, .12)
}

.md-theme-default.md-chip.md-deletable:hover, .md-theme-default.md-chip.md-deletable:focus, .md-theme-default.md-chip.md-editable:hover, .md-theme-default.md-chip.md-editable:focus {
    background-color: rgba(0, 0, 0, .54);
    color: #ffffff
}

.md-theme-default.md-chip.md-deletable:hover .md-delete, .md-theme-default.md-chip.md-deletable:focus .md-delete, .md-theme-default.md-chip.md-editable:hover .md-delete, .md-theme-default.md-chip.md-editable:focus .md-delete {
    color: #ffffff
}

.md-theme-default.md-chip .md-delete {
    color: rgba(0, 0, 0, .38)
}

.md-theme-default.md-chip .md-delete .md-ripple {
    color: #ffffff
}

.md-theme-default.md-chip.md-primary {
    color: rgba(255, 255, 255, .87);
    background-color: #ffc726
}

.md-theme-default.md-chip.md-accent {
    color: rgba(255, 255, 255, .87);
    background-color: #616161
}

.md-theme-default.md-chip.md-warn {
    color: rgba(255, 255, 255, .87);
    background-color: #ff5722
}

.md-theme-default.md-dialog-container .md-dialog {
    background-color: #ffffff;
    color: rgba(0, 0, 0, .87)
}

.md-theme-default.md-icon.md-primary {
    color: #ffc726
}

.md-theme-default.md-icon.md-accent {
    color: #616161
}

.md-theme-default.md-icon.md-warn {
    color: #ff5722
}

.md-theme-default.md-input-container.md-input-invalid:after {
    background-color: #ff5722
}

.md-theme-default.md-input-container.md-input-invalid label, .md-theme-default.md-input-container.md-input-invalid input, .md-theme-default.md-input-container.md-input-invalid textarea, .md-theme-default.md-input-container.md-input-invalid .md-error, .md-theme-default.md-input-container.md-input-invalid .md-count, .md-theme-default.md-input-container.md-input-invalid .md-icon:not(.md-icon-delete) {
    color: #ff5722
}

.md-theme-default.md-input-container.md-input-focused.md-input-inline label {
    color: rgba(0, 0, 0, 0.54)
}

.md-theme-default.md-input-container.md-input-focused.md-input-required label:after {
    color: #ff5722
}

.md-theme-default.md-input-container.md-input-focused:after {
    height: 2px;
    background-color: #ffc726
}

.md-theme-default.md-input-container.md-input-focused input, .md-theme-default.md-input-container.md-input-focused textarea {
    color: #ffc726;
    text-shadow: 0 0 0 rgba(0, 0, 0, .87);
    -webkit-text-fill-color: transparent
}

.md-theme-default.md-input-container.md-input-focused label, .md-theme-default.md-input-container.md-input-focused .md-icon:not(.md-icon-delete) {
    color: #ffc726
}

.md-theme-default.md-input-container.md-input-disabled label, .md-theme-default.md-input-container.md-input-disabled input, .md-theme-default.md-input-container.md-input-disabled textarea, .md-theme-default.md-input-container.md-input-disabled .md-error, .md-theme-default.md-input-container.md-input-disabled .md-count, .md-theme-default.md-input-container.md-input-disabled .md-icon:not(.md-icon-delete), .md-theme-default.md-input-container.md-input-disabled ::-webkit-input-placeholder {
    color: rgba(0, 0, 0, .38)
}

.md-theme-default.md-list {
    background-color: #ffffff;
    color: rgba(0, 0, 0, .87)
}

.md-theme-default.md-list.md-transparent {
    background-color: transparent;
    color: inherit
}

.md-theme-default.md-list .md-list-item .router-link-active.md-list-item-container {
    color: #ffc726
}

.md-theme-default.md-list .md-list-item .router-link-active.md-list-item-container > .md-icon {
    color: #ffc726
}

.md-theme-default.md-list .md-list-item.md-primary .md-list-item-container {
    color: #ffc726
}

.md-theme-default.md-list .md-list-item.md-primary .md-list-item-container > .md-icon {
    color: #ffc726
}

.md-theme-default.md-list .md-list-item.md-accent .md-list-item-container {
    color: #616161
}

.md-theme-default.md-list .md-list-item.md-accent .md-list-item-container > .md-icon {
    color: #616161
}

.md-theme-default.md-list .md-list-item.md-warn .md-list-item-container {
    color: #ff5722
}

.md-theme-default.md-list .md-list-item.md-warn .md-list-item-container > .md-icon {
    color: #ff5722
}

.md-theme-default.md-list .md-list-item-expand .md-list-item-container {
    background-color: #ffffff
}

.md-theme-default.md-list .md-list-item-expand .md-list-item-container:hover, .md-theme-default.md-list .md-list-item-expand .md-list-item-container:focus {
    background-color: rgba(153, 153, 153, 0.2)
}

.md-menu-content .md-theme-default.md-list {
    background-color: #ffffff;
    color: rgba(0, 0, 0, .87)
}

.md-menu-content .md-theme-default.md-list .md-menu-item:hover .md-button:not([disabled]), .md-menu-content .md-theme-default.md-list .md-menu-item:focus .md-button:not([disabled]), .md-menu-content .md-theme-default.md-list .md-menu-item.md-highlighted .md-button:not([disabled]) {
    background-color: rgba(0, 0, 0, .12)
}

.md-menu-content .md-theme-default.md-list .md-menu-item[disabled] {
    color: rgba(0, 0, 0, .38)
}

.md-theme-default.md-boards > .md-boards-navigation {
    background-color: transparent
}

.md-theme-default.md-boards > .md-boards-navigation .md-board-header {
    color: rgba(0, 0, 0, .54)
}

.md-theme-default.md-boards > .md-boards-navigation .md-board-header.md-active, .md-theme-default.md-boards > .md-boards-navigation .md-board-header:active {
    color: #ffc726
}

.md-theme-default.md-boards > .md-boards-navigation .md-board-header.md-disabled {
    color: rgba(0, 0, 0, .26)
}

.md-theme-default.md-boards > .md-boards-navigation .md-button {
    color: #ffc726
}

.md-theme-default.md-boards.md-transparent > .md-boards-navigation {
    background-color: transparent
}

.md-theme-default.md-boards.md-transparent > .md-boards-navigation .md-board-header {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-boards.md-transparent > .md-boards-navigation .md-board-header.md-active, .md-theme-default.md-boards.md-transparent > .md-boards-navigation .md-board-header:active {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-boards.md-transparent > .md-boards-navigation .md-board-header.md-disabled {
    color: rgba(255, 255, 255, .26)
}

.md-theme-default.md-boards.md-transparent > .md-boards-navigation .md-button {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-boards.md-primary > .md-boards-navigation {
    background-color: #ffc726
}

.md-theme-default.md-boards.md-primary > .md-boards-navigation .md-board-header {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-boards.md-primary > .md-boards-navigation .md-board-header.md-active, .md-theme-default.md-boards.md-primary > .md-boards-navigation .md-board-header:active {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-boards.md-primary > .md-boards-navigation .md-board-header.md-disabled {
    color: rgba(255, 255, 255, .26)
}

.md-theme-default.md-boards.md-primary > .md-boards-navigation .md-button {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-boards.md-accent > .md-boards-navigation {
    background-color: #616161
}

.md-theme-default.md-boards.md-accent > .md-boards-navigation .md-board-header {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-boards.md-accent > .md-boards-navigation .md-board-header.md-active, .md-theme-default.md-boards.md-accent > .md-boards-navigation .md-board-header:active {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-boards.md-accent > .md-boards-navigation .md-board-header.md-disabled {
    color: rgba(255, 255, 255, .26)
}

.md-theme-default.md-boards.md-accent > .md-boards-navigation .md-button {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-boards.md-warn > .md-boards-navigation {
    background-color: #ff5722
}

.md-theme-default.md-boards.md-warn > .md-boards-navigation .md-board-header {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-boards.md-warn > .md-boards-navigation .md-board-header.md-active, .md-theme-default.md-boards.md-warn > .md-boards-navigation .md-board-header:active {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-boards.md-warn > .md-boards-navigation .md-board-header.md-disabled {
    color: rgba(255, 255, 255, .26)
}

.md-theme-default.md-boards.md-warn > .md-boards-navigation .md-button {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-progress {
    background-color: rgba(255, 199, 38, .38)
}

.md-theme-default.md-progress:not(.md-indeterminate) .md-progress-track {
    background-color: #ffc726
}

.md-theme-default.md-progress .md-progress-track:after, .md-theme-default.md-progress .md-progress-track:before {
    background-color: #ffc726
}

.md-theme-default.md-progress.md-accent {
    background-color: rgba(97, 97, 97, .38)
}

.md-theme-default.md-progress.md-accent:not(.md-indeterminate) .md-progress-track {
    background-color: #616161
}

.md-theme-default.md-progress.md-accent .md-progress-track:after, .md-theme-default.md-progress.md-accent .md-progress-track:before {
    background-color: #616161
}

.md-theme-default.md-progress.md-warn {
    background-color: rgba(255, 87, 34, .38)
}

.md-theme-default.md-progress.md-warn:not(.md-indeterminate) .md-progress-track {
    background-color: #ff5722
}

.md-theme-default.md-progress.md-warn .md-progress-track:after, .md-theme-default.md-progress.md-warn .md-progress-track:before {
    background-color: #ff5722
}

.md-theme-default.md-radio .md-radio-container:after {
    background-color: #616161
}

.md-theme-default.md-radio.md-checked .md-radio-container {
    border-color: #616161
}

.md-theme-default.md-radio.md-checked .md-ink-ripple {
    color: #616161
}

.md-theme-default.md-radio.md-checked .md-ripple {
    opacity: .38
}

.md-theme-default.md-radio.md-primary .md-radio-container:after {
    background-color: #ffc726
}

.md-theme-default.md-radio.md-primary.md-checked .md-radio-container {
    border-color: #ffc726
}

.md-theme-default.md-radio.md-primary.md-checked .md-ink-ripple {
    color: #ffc726
}

.md-theme-default.md-radio.md-warn .md-radio-container:after {
    background-color: #ff5722
}

.md-theme-default.md-radio.md-warn.md-checked .md-radio-container {
    border-color: #ff5722
}

.md-theme-default.md-radio.md-warn.md-checked .md-ink-ripple {
    color: #ff5722
}

.md-theme-default.md-radio.md-disabled .md-radio-container {
    border-color: rgba(0, 0, 0, 0.26)
}

.md-theme-default.md-radio.md-disabled .md-radio-container:after {
    background-color: rgba(0, 0, 0, 0.26)
}

.md-theme-default.md-radio.md-disabled.md-checked .md-radio-container {
    border-color: rgba(0, 0, 0, 0.26)
}

.md-theme-default.md-rating-bar > .md-empty-icon .md-icon {
    color: rgba(0, 0, 0, .26)
}

.md-theme-default.md-rating-bar > .md-full-icon .md-icon {
    color: rgba(0, 0, 0, .38)
}

.md-theme-default.md-rating-bar.md-primary > .md-full-icon .md-icon {
    color: #ffc726
}

.md-theme-default.md-rating-bar.md-accent > .md-full-icon .md-icon {
    color: #616161
}

.md-theme-default.md-rating-bar.md-warn > .md-full-icon .md-icon {
    color: #ff5722
}

.md-theme-default.md-select:after {
    color: rgba(0, 0, 0, .54)
}

.md-theme-default.md-select:after {
    color: rgba(0, 0, 0, .38)
}

.md-theme-default.md-select-content .md-menu-item.md-selected, .md-theme-default.md-select-content .md-menu-item.md-checked {
    color: #ffc726
}

.md-theme-default.md-sidenav .md-sidenav-content {
    background-color: #ffffff;
    color: rgba(0, 0, 0, .87)
}

.md-theme-default .md-snackbar .md-ink-ripple, .md-theme-default.md-snackbar .md-ink-ripple {
    color: #ffffff
}

.md-theme-default.md-spinner .md-spinner-path {
    stroke: #ffc726
}

.md-theme-default.md-spinner.md-accent .md-spinner-path {
    stroke: #616161
}

.md-theme-default.md-spinner.md-warn .md-spinner-path {
    stroke: #ff5722
}

.md-theme-default.md-stepper .md-step-header .md-step-icon, .md-theme-default.md-stepper .md-step-header .md-step-number {
    color: rgba(0, 0, 0, .87);
    background-color: #bdbdbd
}

.md-theme-default.md-stepper .md-step-header.md-primary .md-step-icon, .md-theme-default.md-stepper .md-step-header.md-primary .md-step-number, .md-theme-default.md-stepper .md-step-header.md-active .md-step-icon, .md-theme-default.md-stepper .md-step-header.md-active .md-step-number {
    color: rgba(255, 255, 255, .87);
    background-color: #ffc726
}

.md-theme-default.md-stepper .md-step-header.md-accent .md-step-icon, .md-theme-default.md-stepper .md-step-header.md-accent .md-step-number {
    color: rgba(255, 255, 255, .87);
    background-color: #616161
}

.md-theme-default.md-stepper .md-step-header.md-warn .md-step-icon, .md-theme-default.md-stepper .md-step-header.md-warn .md-step-number {
    color: rgba(255, 255, 255, .87);
    background-color: #ff5722
}

.md-theme-default.md-stepper .md-step-header.md-warn .md-step-error, .md-theme-default.md-stepper .md-step-header.md-warn .md-step-titles {
    color: #ff5722
}

.md-theme-default.md-stepper .md-step-header.md-disabled {
    color: #bdbdbd
}

.md-theme-default.md-stepper .md-step-header.md-disabled .md-step-icon, .md-theme-default.md-stepper .md-step-header.md-disabled .md-step-number {
    color: white;
    background-color: #bdbdbd
}

.md-theme-default.md-subheader.md-primary {
    color: #ffc726
}

.md-theme-default.md-subheader.md-accent {
    color: #616161
}

.md-theme-default.md-subheader.md-warn {
    color: #ff5722
}

.md-theme-default.md-switch.md-checked .md-switch-container {
    background-color: rgba(158, 158, 158, 0.5)
}

.md-theme-default.md-switch.md-checked .md-switch-thumb {
    background-color: #616161
}

.md-theme-default.md-switch.md-checked .md-ink-ripple {
    color: #616161
}

.md-theme-default.md-switch.md-checked .md-ripple {
    opacity: .38
}

.md-theme-default.md-switch.md-checked.md-primary .md-switch-container {
    background-color: rgba(255, 199, 38, 0.5)
}

.md-theme-default.md-switch.md-checked.md-primary .md-switch-thumb {
    background-color: #ffc726
}

.md-theme-default.md-switch.md-checked.md-primary .md-ink-ripple {
    color: #ffc726
}

.md-theme-default.md-switch.md-checked.md-warn .md-switch-container {
    background-color: rgba(255, 87, 34, 0.5)
}

.md-theme-default.md-switch.md-checked.md-warn .md-switch-thumb {
    background-color: #ff5722
}

.md-theme-default.md-switch.md-checked.md-warn .md-ink-ripple {
    color: #ff5722
}

.md-theme-default.md-switch.md-disabled .md-switch-container, .md-theme-default.md-switch.md-disabled.md-checked .md-switch-container {
    background-color: rgba(0, 0, 0, 0.12)
}

.md-theme-default.md-switch.md-disabled .md-switch-thumb, .md-theme-default.md-switch.md-disabled.md-checked .md-switch-thumb {
    background-color: #bdbdbd
}

.md-theme-default.md-table-card .md-toolbar {
    background-color: #ffffff;
    color: rgba(0, 0, 0, .87)
}

.md-theme-default.md-table-alternate-header {
    background-color: #ffffff
}

.md-theme-default.md-table-alternate-header .md-toolbar {
    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(0, 0, 0, .87)
}

.md-theme-default.md-table-alternate-header .md-counter {
    color: #616161
}

.md-theme-default.md-tabs > .md-tabs-navigation {
    background-color: #ffc726
}

.md-theme-default.md-tabs > .md-tabs-navigation .md-tab-header {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-tabs > .md-tabs-navigation .md-tab-header.md-active, .md-theme-default.md-tabs > .md-tabs-navigation .md-tab-header:focus {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-tabs > .md-tabs-navigation .md-tab-header.md-disabled {
    color: rgba(255, 255, 255, .26)
}

.md-theme-default.md-tabs > .md-tabs-navigation .md-tab-indicator {
    background-color: #616161
}

.md-theme-default.md-tabs > .md-tabs-navigation .md-tab-header-navigation-button {
    color: rgba(255, 255, 255, .54);
    background-color: #ffc726
}

.md-theme-default.md-tabs.md-transparent > .md-tabs-navigation {
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, .12)
}

.md-theme-default.md-tabs.md-transparent > .md-tabs-navigation .md-tab-header {
    color: rgba(0, 0, 0, .54)
}

.md-theme-default.md-tabs.md-transparent > .md-tabs-navigation .md-tab-header.md-active, .md-theme-default.md-tabs.md-transparent > .md-tabs-navigation .md-tab-header:focus {
    color: #ffc726
}

.md-theme-default.md-tabs.md-transparent > .md-tabs-navigation .md-tab-header.md-disabled {
    color: rgba(0, 0, 0, .26)
}

.md-theme-default.md-tabs.md-transparent > .md-tabs-navigation .md-tab-indicator {
    background-color: #ffc726
}

.md-theme-default.md-tabs.md-accent > .md-tabs-navigation {
    background-color: #616161
}

.md-theme-default.md-tabs.md-accent > .md-tabs-navigation .md-tab-header {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-tabs.md-accent > .md-tabs-navigation .md-tab-header.md-active, .md-theme-default.md-tabs.md-accent > .md-tabs-navigation .md-tab-header:focus {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-tabs.md-accent > .md-tabs-navigation .md-tab-header.md-disabled {
    color: rgba(255, 255, 255, .26)
}

.md-theme-default.md-tabs.md-accent > .md-tabs-navigation .md-tab-indicator {
    background-color: #ffffff
}

.md-theme-default.md-tabs.md-warn > .md-tabs-navigation {
    background-color: #ff5722
}

.md-theme-default.md-tabs.md-warn > .md-tabs-navigation .md-tab-header {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-tabs.md-warn > .md-tabs-navigation .md-tab-header.md-active, .md-theme-default.md-tabs.md-warn > .md-tabs-navigation .md-tab-header:focus {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-tabs.md-warn > .md-tabs-navigation .md-tab-header.md-disabled {
    color: rgba(255, 255, 255, .26)
}

.md-theme-default.md-tabs.md-warn > .md-tabs-navigation .md-tab-indicator {
    background-color: #ffffff
}

.md-theme-default.md-toolbar {
    background-color: #ffc726;
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar .md-input-container.md-input-focused input, .md-theme-default.md-toolbar .md-input-container.md-input-focused textarea {
    color: rgba(255, 255, 255, .87);
    text-shadow: 0 0 0 rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar .md-input-container.md-input-focused label, .md-theme-default.md-toolbar .md-input-container.md-input-focused .md-icon:not(.md-icon-delete) {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar .md-input-container:after {
    background-color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar .md-input-container input, .md-theme-default.md-toolbar .md-input-container textarea {
    color: rgba(255, 255, 255, .87);
    text-shadow: 0 0 0 rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar .md-input-container input::-webkit-input-placeholder, .md-theme-default.md-toolbar .md-input-container textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-toolbar .md-input-container label, .md-theme-default.md-toolbar .md-input-container .md-icon:not(.md-icon-delete) {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar.md-accent {
    background-color: #616161;
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar.md-accent .md-input-container.md-input-focused input, .md-theme-default.md-toolbar.md-accent .md-input-container.md-input-focused textarea {
    color: rgba(255, 255, 255, .87);
    text-shadow: 0 0 0 rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar.md-accent .md-input-container.md-input-focused label, .md-theme-default.md-toolbar.md-accent .md-input-container.md-input-focused .md-icon:not(.md-icon-delete) {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar.md-accent .md-input-container:after {
    background-color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar.md-accent .md-input-container input, .md-theme-default.md-toolbar.md-accent .md-input-container textarea {
    color: rgba(255, 255, 255, .87);
    text-shadow: 0 0 0 rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar.md-accent .md-input-container input::-webkit-input-placeholder, .md-theme-default.md-toolbar.md-accent .md-input-container textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-toolbar.md-accent .md-input-container label, .md-theme-default.md-toolbar.md-accent .md-input-container .md-icon:not(.md-icon-delete) {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar.md-warn {
    background-color: #ff5722;
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar.md-warn .md-input-container.md-input-focused input, .md-theme-default.md-toolbar.md-warn .md-input-container.md-input-focused textarea {
    color: rgba(255, 255, 255, .87);
    text-shadow: 0 0 0 rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar.md-warn .md-input-container.md-input-focused label, .md-theme-default.md-toolbar.md-warn .md-input-container.md-input-focused .md-icon:not(.md-icon-delete) {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar.md-warn .md-input-container:after {
    background-color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar.md-warn .md-input-container input, .md-theme-default.md-toolbar.md-warn .md-input-container textarea {
    color: rgba(255, 255, 255, .87);
    text-shadow: 0 0 0 rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar.md-warn .md-input-container input::-webkit-input-placeholder, .md-theme-default.md-toolbar.md-warn .md-input-container textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .54)
}

.md-theme-default.md-toolbar.md-warn .md-input-container label, .md-theme-default.md-toolbar.md-warn .md-input-container .md-icon:not(.md-icon-delete) {
    color: rgba(255, 255, 255, .87)
}

.md-theme-default.md-toolbar.md-transparent {
    background-color: transparent;
    color: rgba(0, 0, 0, .87)
}

.md-theme-default.md-toolbar.md-transparent .md-input-container.md-input-focused input, .md-theme-default.md-toolbar.md-transparent .md-input-container.md-input-focused textarea {
    color: rgba(0, 0, 0, .87);
    text-shadow: 0 0 0 rgba(0, 0, 0, .87)
}

.md-theme-default.md-toolbar.md-transparent .md-input-container.md-input-focused label, .md-theme-default.md-toolbar.md-transparent .md-input-container.md-input-focused .md-icon:not(.md-icon-delete) {
    color: rgba(0, 0, 0, .87)
}

.md-theme-default.md-toolbar.md-transparent .md-input-container:after {
    background-color: rgba(0, 0, 0, .87)
}

.md-theme-default.md-toolbar.md-transparent .md-input-container input, .md-theme-default.md-toolbar.md-transparent .md-input-container textarea {
    color: rgba(0, 0, 0, .87);
    text-shadow: 0 0 0 rgba(0, 0, 0, .87)
}

.md-theme-default.md-toolbar.md-transparent .md-input-container input::-webkit-input-placeholder, .md-theme-default.md-toolbar.md-transparent .md-input-container textarea::-webkit-input-placeholder {
    color: rgba(0, 0, 0, .54)
}

.md-theme-default.md-toolbar.md-transparent .md-input-container label, .md-theme-default.md-toolbar.md-transparent .md-input-container .md-icon:not(.md-icon-delete) {
    color: rgba(0, 0, 0, .87)
}
