
.password-validation-checks {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .password-validation-checks--item {
        display: flex;
        font-size: $fz--small;
        align-items: center;
        gap: 8px;
    }

    .password-validation-checks--icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        width: 16px;
        height: 16px;
        background: $c--monochrome-light-grey;

    }

    .password-validation-checks--icon-symbol.password-validation-checks--icon-symbol {
        width: 12px;
        height: 12px;
        min-width: auto;
        min-height: auto;
        font-size: 12px;
    }

    // Facets
    & {
        .password-validation-checks--icon {
            &.password-validation-checks--item__checked {
                background: $c--signal-success;
                color: $c--monochrome-white;
            }
        }
    }


    // States
}
