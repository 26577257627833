.table-col-note {
    // Vars
    $column-width: 140px;

    // Support

    // Module
    & {
        position: relative;
        width: $column-width;
        min-width: $column-width;
        max-width: $column-width;
        white-space: normal;

        .table-col-note--error {
            font-size: 1.0rem;
        }

        .table-col-note--wrap {
            position: relative;
            display: flex;
        }

        .table-col-note--data-display {
            position: relative;
            display: block;
            width: $column-width;
            padding: 8px 0;
            text-align: left;
            cursor: pointer;
        }

        .table-col-note--input {
            width: $column-width;
        }
    }

    // Facets

    // States
}
