.table-col-actions-driver-visibility {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        width: auto;
        white-space: nowrap;

        .table-col-actions-driver-visibility--list {
            position: relative;
        }

        .table-col-actions-driver-visibility--spinner-wrap {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            color: $c--brand-yellow;
        }

        .table-col-actions-driver-visibility--items {
            transition: opacity $trs--base;
        }
    }

    // Facets
    & {
        &.table-col-actions-driver-visibility__is-pending {
            .table-col-actions-driver-visibility--items {
                opacity: 0.5;
            }
        }
    }

    // States
}
