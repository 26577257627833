.ink-ripple {
    // Vars
    $swift-ease-out-duration: 400ms !default;
    $swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
    $swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;

    $swift-ease-in-duration: 300ms !default;
    $swift-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2) !default;
    $swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function !default;

    // Support
    @keyframes ripple {
        to {
            transform: scale(2.2) translateZ(0);
        }
    }

    // Module
    & {
        pointer-events: none;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: inherit;
        mask-image: radial-gradient(circle, $c--monochrome-white 100%, $c--monochrome-black 100%);
        transition: $swift-ease-in;

        .ink-ripple--position-holder {
            @include overlay();
        }

        .ink-ripple--ripple {
            position: absolute;
            background-color: currentColor;
            border-radius: 50%;
            opacity: 0.2;
            transform: scale(0) translateZ(0);
            transition: none;
            will-change: background-color, opacity, transform, width, height, top, left;

            &.ink-ripple--ripple__is-active {
                animation: ripple 1000ms $swift-ease-out-timing-function forwards;

                &.ink-ripple--ripple__is-fading {
                    opacity: 0 !important; // stylelint-disable-line declaration-no-important
                    transition: $swift-ease-out;
                    transition-duration: 600ms;
                }
            }
        }
    }

    // Facets

    // States
}
