.table-col-custom-container-select {
    // Vars

    // Support

    // Module
    & {
        .table-col-custom-container-select--checkbox {
            white-space: nowrap;
        }
    }

    // Facets

    // States
}
