// Sizes

/// Border radius
$sz--border-radius-base: 2px;
$sz--border-width-base: 2px;

// Table
$sz--table-spacing-regular: 0 8px 0 8px;
$sz--table-header-offset: 24px;
$sz--table-content-offset: 16px;
$sz--table-cell-height: 66px;
$sz--table-row-expanded-height: 620px;

// Table col actions
$sz--table-col-actions-icon: 24px;
$sz--table-col-actions-icon-padding: 5px;
$sz--table-col-actions-gutter: 4px;

// Navigation
$sz--top-menu-height: 80px;

// Tabs
$sz--tabs-main-height: 66px;

// Footer
$sz--footer-height: 64px;

// Sidebar
$sz--sidenav: 600px;
$sz--chat-flyout: 360px;
