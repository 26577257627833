.table-col-actions-unassign {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        width: 100%;
        white-space: nowrap;

        .table-col-actions-unassign--list {
            width: table-col-utils--button-bar-width(3, $sz--table-col-actions-icon, $sz--table-col-actions-icon-padding, $sz--table-col-actions-gutter);
        }
    }

    // Facets

    // States
}
