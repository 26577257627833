@use 'sass:math';

@mixin icon-utils--strike-through-base($sz--strike-through, $c--foreground, $c--background) {
    $sz--strike-through-offset: math.div($sz--strike-through, math.pow(2, 0.5));

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 40px;
        height: $sz--strike-through;

        opacity: 0;
        transition: opacity $trs--base;
    }

    &::before {
        transform: translate($sz--strike-through-offset * 0.5, -$sz--strike-through-offset * 0.5) translate(-50%, -50%) rotate(45deg);
        background-color: $c--background;
    }

    &::after {
        transform: translate(-$sz--strike-through-offset * 0.5, $sz--strike-through-offset * 0.5) translate(-50%, -50%) rotate(45deg);
        background-color: $c--foreground;
    }
}

@mixin icon-utils--strike-through-active() {
    &::before,
    &::after {
        opacity: 1;
    }
}
