/// Circle shaped button
///
@mixin btn--square() {
    // Vars

    // Support

    // Module
    & {
        min-width: 0;

        .btn--icon {
            margin: 0;
        }
    }

    // Facets
    & {
        &.btn__small {
            @include btn--size-square-small;
        }

        &.btn__medium {
            @include btn--size-square-medium;
        }
    }

    // States
}
