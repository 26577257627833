.table-col-driver-with-carrier {
    // Vars
    $column-min-width: 140px;
    $column-max-width: 140px;

    // Support

    // Module
    & {
        width: 100%;
        min-width: $column-min-width;
        max-width: $column-max-width;
        padding: 8px 0;
        white-space: normal;
        word-break: keep-all;
        hyphens: none;
    }

    // Facets

    // States
}
