// stylelint-disable

////
/// Helper classes mostly for bugfixes that depend on additional javascript, that must be included.
////

/// IEmobile viewport fix
/// Handles buggy viewport behaviour in all mobile IE versions. Needs accompanying js to work:
/// ```
/// function ieMobileViewportFix() {
///     if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
///         var msViewportStyle = document.createElement("style");
///         msViewportStyle.appendChild(
///             document.createTextNode(
///                 "@-ms-viewport{width:auto!important}"
///             )
///         );
///         document.getElementsByTagName("head")[0].
///             appendChild(msViewportStyle);
///     }
/// }
/// ```
///
@-webkit-viewport { width: device-width; }
@-moz-viewport { width: device-width; }
@-ms-viewport { width: device-width; }
@-o-viewport { width: device-width; }
@viewport { width: device-width; }
