/** postcss-bem-linter: define page-order-detail */

.page-order-detail {
    // Vars

    // Support

    // Module
    & {
        .page-order-detail--title {
            padding: 60px 0 0;
        }

        .page-order-detail--pdf-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin: ($sz--table-content-offset * -0.25) $sz--table-content-offset * 0.75;
        }

        .page-order-detail--pdf-link {
            margin: $sz--table-content-offset * 0.25;
        }

        .page-order-detail--pdf-button {
            display: inline-block;
        }
    }

    // Facets

    // States
}
